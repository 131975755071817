export default {
  objectToLoad: {
    // eslint-disable-next-line camelcase
    black_joker: {
      path: `/shapes/svg/joker-black.svg`,
      left: 300,
      top: 300,
      scaleX: 1.5,
      scaleY: 1.5
    },
    // eslint-disable-next-line camelcase
    red_joker: {
      path: `/shapes/svg/joker-red.svg`,
      left: 300,
      top: 300,
      scaleX: 1.5,
      scaleY: 1.5
    }
  }
};
