import * as THREE from "three";

export default (scene) => {
  const directionalLight = new THREE.DirectionalLight(0x555555);
  const ambient = new THREE.AmbientLight(0xaaaaaa);

  directionalLight.position.set(1, 1, 1);
  scene.add(directionalLight);
  scene.add(ambient);
};
