export const AFTER = `AFTER`;
export const BEFORE = `BEFORE`;
export const APPLY_BACKGROUND = `APPLY_BACKGROUND`;
export const OBJECT_MODIFIED = `OBJECT_MODIFIED`;
export const CANVAS_MODIFIED = `CANVAS_MODIFIED`;
export const OBJECT_MOVING = `OBJECT_MOVING`;
export const OBJECT_SCALING = `OBJECT_SCALING`;
export const OBJECT_ROTATING = `OBJECT_ROTATING`;
export const OBJECT_ADDED = `OBJECT_ADDED`;
export const OBJECT_REMOVED = `OBJECT_REMOVED`;
export const BACKGROUND_COLOR_CHANGED = `BACKGROUND_COLOR_CHANGED`;
export const SELECTION_CREATED = `SELECTION_CREATED`;
export const SELECTION_CLEARED = `SELECTION_CLEARED`;
export const SELECTION_UPDATED = `SELECTION_UPDATED`;
export const SCALLED_UP_IMAGES = `SCALLED_UP_IMAGES`;
export const HISTORY_CHANGED = `HISTORY_CHANGED`;
export const HISTORY_ADD = `HISTORY_ADD`;
export const HAS_COPIED_OBJECT = `HAS_COPIED_OBJECT`;
export const TEXT_CHANGED = `TEXT_CHANGED`;
export const TOUCH_MOVE = `TOUCH_MOVE`;
export const MOUSE_DOWN = `MOUSE_DOWN`;
export const FILTER_CHANGED = `FILTER_CHANGED`;
export const OPACITY_CHANGED = `OPACITY_CHANGED`;
export const FILL_CHANGED = `FILL_CHANGED`;
export const TEXT_ADDED = `TEXT_ADDED`;
export const CROP_APPLYIED = `CROP_APPLYIED`;
export const STROKE_CHANGED = `STROKE_CHANGED`;
export const STATIC_OBJECT_FILL_CHANGED = `STATIC_OBJECT_FILL_CHANGED`;
export const TILING_DESTROYED = `TILING_DESTROYED`;
export const TILING_CREATED = `TILING_CREATED`;
