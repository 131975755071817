import * as THREE from "three";
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import SceneSubject from "./SceneSubject";
import GeneralLights from "./GeneralLights";

// The SceneManager is responsible for:

// Creating the scene
// Creating the renderer
// Creating the camera
// Creating the controls
// Creating the scene subjects
// Create handler events
// Starting the rotate loop

export default class SceneManager {
  constructor(canvas) {
    const {width, height} = canvas;
    this.canvas = canvas;
    this.screenDimensions = {
      width,
      height
    };
    this.mousePosition = {
      x: 0,
      y: 0
    };
    this.scene = this._buildScene();
    this.renderer = this._buildRenderer(canvas, this.screenDimensions);
    this.camera = this._buildCamera(this.screenDimensions);
    this.controls = this._buildControls(this.camera, canvas);
    this.lights = this._addSceneLights(this.scene);
    this.subject = this._createSceneSubject(this.scene);
  }

  async applySettings(settings) {
    await this.subject.applySettings(settings);
    this._updateCameraPosition(settings);
  }

  update() {
    this.controls.update();
    this.renderer.render(this.scene, this.camera);
  }

  onWindowResize() {
    const {width, height} = this.canvas;

    this.screenDimensions.width = width;
    this.screenDimensions.height = height;

    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();

    this.renderer.setSize(width, height);
  }

  onMouseMove(x, y) {
    this.mousePosition.x = x;
    this.mousePosition.y = y;
  }

  // private methods
  _buildScene() {
    const scene = new THREE.Scene();
    scene.background = new THREE.Color(`#FFF`);

    return scene;
  }
  _buildRenderer(canvas, {width, height}) {
    const renderer = new THREE.WebGLRenderer({
      canvas,
      antialias: true,
      alpha: true
    });
    const DPR = window && window.devicePixelRatio ? window.devicePixelRatio : 1;
    renderer.setPixelRatio(DPR);
    renderer.setSize(width, height);

    renderer.gammaInput = true;
    renderer.gammaOutput = true;

    return renderer;
  }
  _buildControls(cam, el) {
    return new OrbitControls(cam, el);
  }
  _buildCamera({width, height}) {
    const aspectRatio = width / height;
    const fieldOfView = 60;
    const nearPlane = 1;
    const farPlane = 10000;
    const camera = new THREE.PerspectiveCamera(
      fieldOfView,
      aspectRatio,
      nearPlane,
      farPlane
    );

    camera.position.z = 300;

    return camera;
  }
  _createSceneSubject(scene, settings) {
    return new SceneSubject(scene, settings);
  }
  _addSceneLights(scene) {
    return new GeneralLights(scene);
  }
  _updateCameraPosition(settings) {
    if (settings && settings.camera) {
      this.camera.position.set(...settings.camera);
    } else {
      this.camera.position.set(-70, 100, 100);
    }
    this.camera.rotation.set(-1.53, -0.87, -1.52);
  }
}
