import React from "react";
import PropTypes from "prop-types";
import {ConnectedRouter} from "connected-react-router";
import {StaticRouter} from "react-router";
import history from "./history";

class Router extends React.Component {
  static propTypes = {
    children: PropTypes.element,
    path: PropTypes.string
  };

  renderRouter = () => {
    if (typeof window === `object`) {
      return (
        <ConnectedRouter history={history} noInitialPop>
          {this.props.children}
        </ConnectedRouter>
      );
    } else {
      return (
        <StaticRouter location={this.props.path} context={{}}>
          {this.props.children}
        </StaticRouter>
      );
    }
  };

  render() {
    return this.renderRouter();
  }
}

export default Router;
