"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("../constants");
var store_1 = __importStar(require("../components/HeaderApp/store"));
var cache = {};
var apiMiddleware = function (store) { return function (next) { return function (action) {
    next(action);
    var callAPI = action.callAPI, payload = action.payload, type = action.type, rest = __rest(action, ["callAPI", "payload", "type"]);
    if (type !== constants_1.CHANGE_LOCATION)
        return;
    var url = payload.location.pathname + payload.location.search + payload.location.hash;
    var scope;
    if (/edit_v2|new_v2/.test(url))
        return;
    store_1.default.dispatch((0, store_1.showBannerAction)(true));
    if (/store|favorites/.test(url))
        scope = constants_1.STORE;
    else if (/\/\w+\/\d+/.test(url)) {
        store_1.default.dispatch((0, store_1.showBannerAction)(false));
        scope = constants_1.ITEM;
    }
    else
        scope = constants_1.STORE;
    store.dispatch(__assign({ type: scope + constants_1.LOAD_DATA + constants_1.START, payload: payload }, rest));
    if (cache[url]) {
        store.dispatch(__assign(__assign({}, rest), { payload: payload, response: __assign({}, (cache[url].state || cache[url])), type: scope + constants_1.LOAD_DATA + constants_1.SUCCESS }));
    }
    else {
        fetch(url, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: 'same-origin',
        })
            .then(function (res) {
            if (res.status >= 400) {
                throw new Error('Error');
            }
            return res.json();
        })
            .then(function (response) {
            cache[url] = __assign({}, response); // add to local cache
            store.dispatch(__assign(__assign({}, rest), { payload: payload, response: response.state || response, type: scope + constants_1.LOAD_DATA + constants_1.SUCCESS }));
        })
            .catch(function (error) {
            return store.dispatch(__assign(__assign({}, rest), { payload: payload, error: error, type: scope + constants_1.LOAD_DATA + constants_1.FAIL }));
        });
    }
    return void 0;
}; }; };
exports.default = apiMiddleware;
