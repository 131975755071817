/* eslint-disable camelcase */
import {
  colorOfMaterial,
  material,
  canvas,
  cards,
  guideLines,
  chocolate,
  poster,
  fullPrint,
  mousePad
} from "./templates";
import full_print_mug from "./full-print-mug";
import calendar_single_a2 from "./calendar-single-a2";
import calendar_a3 from "./calendar-a3";
import round_plastic_clock from "./round-plastic-clock";
import round_wood_clock from "./round-plastic-clock";
import square_wood_clock from "./square-wood-clock";
import umbrella_bilateral from "./umbrella-bilateral";
import playing_card from "./playing-card";

export default {
  apron: guideLines,
  backpack: fullPrint,
  badge: fullPrint,
  bag: colorOfMaterial,
  bandana: fullPrint,
  basketball_uniform: fullPrint,
  beanie: colorOfMaterial,
  beer_mug: guideLines,
  bib: colorOfMaterial,
  bodysuit: colorOfMaterial,
  bottle_sticker: fullPrint,
  buff: fullPrint,
  business_card: cards,
  calendar_a2: {...calendar_a3, ...fullPrint},
  calendar_a3: {...calendar_a3, ...fullPrint},
  calendar_single_a2: {...calendar_single_a2, ...fullPrint},
  can: guideLines,
  candle: colorOfMaterial,
  canvas20x30: canvas,
  canvas30x30: canvas,
  canvas30x40: canvas,
  canvas30x60: canvas,
  canvas40x55: canvas,
  canvas50x50: canvas,
  canvas50x75: canvas,
  canvas60x90: canvas,
  cap_trucker: colorOfMaterial,
  cardholder: colorOfMaterial,
  chocolate,
  chocolate35x35: chocolate,
  chocolate_set12: fullPrint,
  chocolate_set16: fullPrint,
  chocolate_set4: fullPrint,
  chocolate_set_souffle: fullPrint,
  chocolate_set_shell: fullPrint,
  chistmas_ball: fullPrint,
  chistmas_tree_toy: fullPrint,
  city_backpack: guideLines,
  classic_polo_shirt: colorOfMaterial,
  classic_tee: colorOfMaterial,
  classic_woman_polo: colorOfMaterial,
  classic_woman_tee: colorOfMaterial,
  colored_handle_mug: colorOfMaterial,
  colored_hood_and_sleeves_hoodie: colorOfMaterial,
  colored_inside_mug: colorOfMaterial,
  colored_pocket_hoodie: colorOfMaterial,
  cotton_backpack: colorOfMaterial,
  crop_tee: colorOfMaterial,
  cube: fullPrint,
  cutting_board: guideLines,
  dog_tee: guideLines,
  euro_business_card: cards,
  flag22x15: fullPrint,
  flag135x90: fullPrint,
  flag150x100: fullPrint,
  flash_driver: colorOfMaterial,
  football_uniform: fullPrint,
  free_form_sticker: material,
  full_print_apron: fullPrint,
  full_print_bag: fullPrint,
  full_print_backpack: fullPrint,
  full_print_beanie: fullPrint,
  full_print_boy_tee: fullPrint,
  full_print_gloves: fullPrint,
  full_print_girl_tee: fullPrint,
  full_print_hoodie: fullPrint,
  full_print_kids_sweatshirt: fullPrint,
  full_print_mug,
  full_print_man_polo: fullPrint,
  full_print_neck_pillow: fullPrint,
  full_print_pencil_case: fullPrint,
  full_print_sport_tee: fullPrint,
  full_print_sweatshirt: fullPrint,
  full_print_tanktop: fullPrint,
  full_print_tee: fullPrint,
  full_print_waist_bag: fullPrint,
  full_print_woman_hoodie: fullPrint,
  full_print_woman_polo: fullPrint,
  full_print_woman_sweatshirt: fullPrint,
  full_print_woman_tanktop: fullPrint,
  full_print_woman_tee: fullPrint,
  galaxy_s6_case: material,
  galaxy_s6_edge_case: material,
  galaxy_s7_case: material,
  galaxy_s7_edge_case: material,
  galaxy_s8_case: material,
  galaxy_s8_plus_case: material,
  gift_bag8x12: fullPrint,
  gift_bag15x22: fullPrint,
  gift_bag20x30: fullPrint,
  hat: colorOfMaterial,
  heart_magnet75x97: fullPrint,
  heart_mouse_pad: fullPrint,
  heart_sticker50x65: material,
  heart_sticker75x97: material,
  hockey_uniform: fullPrint,
  hoodie: colorOfMaterial,
  iphone_x_case: material,
  iphone14_pro_max_case: material,
  iphone14_pro_case: material,
  iphone14_case: material,
  iphone13_case: material,
  iphone13_pro_case: material,
  iphone13_pro_max_case: material,
  iphone12_case: material,
  iphone12_pro_case: material,
  iphone12_mini_case: material,
  iphone11_case: material,
  iphone11_pro_case: material,
  iphone11_pro_max_case: material,
  iphone5_case: material,
  iphone5_volumetric_case: material,
  iphone6_case: material,
  iphone6_volumetric_case: material,
  iphone6_plus_case: material,
  iphone7_case: material,
  iphone7_plus_case: material,
  iphone8_case: material,
  iphone8_plus_case: material,
  kids_football_uniform: fullPrint,
  kids_hoodie: colorOfMaterial,
  kids_panama: fullPrint,
  kids_sport_tee: fullPrint,
  kids_sweatshirt: colorOfMaterial,
  kids_tee: colorOfMaterial,
  kids_zip_hoodie: colorOfMaterial,
  kitchen_scale: guideLines,
  knitted_hat: colorOfMaterial,
  large_classic_tee: colorOfMaterial,
  large_envelope: guideLines,
  large_full_print_tee: fullPrint,
  large_pencil_case: fullPrint,
  leggings: fullPrint,
  leather_passport_cover: colorOfMaterial,
  lighter: fullPrint,
  long_socks: fullPrint,
  longsleeve: colorOfMaterial,
  man_bomber: colorOfMaterial,
  man_pants: colorOfMaterial,
  man_pajama_pants: fullPrint,
  man_sneakers: fullPrint,
  man_stretch_tee: colorOfMaterial,
  man_suspenders: fullPrint,
  man_underpants: fullPrint,
  mask: fullPrint,
  mask_scarf: fullPrint,
  masquerade_balaclava: fullPrint,
  medium_envelope: guideLines,
  metal_bottle: guideLines,
  moneybox: guideLines,
  mouse_pad: mousePad,
  mug: guideLines,
  nametag: guideLines,
  notepad: fullPrint,
  notepad_a4: fullPrint,
  notebook_glued: fullPrint,
  notebook_spring: fullPrint,
  notebook_stapled: fullPrint,
  oval_sticker150x90: material,
  oval_sticker98x70: material,
  oversize_hoodie: colorOfMaterial,
  oversize_tee: colorOfMaterial,
  oversize_pocket_tee: colorOfMaterial,
  package155x220x50: fullPrint,
  package220x155x50: fullPrint,
  package250x350x80: fullPrint,
  paired_tee: colorOfMaterial,
  paired_wineglass: guideLines,
  panama: fullPrint,
  passport_cover: material,
  pen: colorOfMaterial,
  pencil: colorOfMaterial,
  phone_cardholder: colorOfMaterial,
  photo_tile: canvas,
  pillow: colorOfMaterial,
  pillow40x40: fullPrint,
  pillow60x40: fullPrint,
  pillow150x50: fullPrint,
  plaid_fleece130x170: fullPrint,
  planner: colorOfMaterial,
  plastic_bottle: guideLines,
  plastic_pen: guideLines,
  plate: guideLines,
  playing_card,
  polo_shirt: colorOfMaterial,
  postcard: fullPrint,
  postcard15x10: fullPrint,
  postcard21x10: fullPrint,
  poster_a2: poster,
  poster,
  powerbank: colorOfMaterial,
  rectangle_keychain35x25: fullPrint,
  rectangle_keychain50x35: fullPrint,
  rectangle_magnet150x45: fullPrint,
  rectangle_magnet90x50: fullPrint,
  rectangle_sticker150x45: material,
  rectangle_sticker90x50: material,
  puzzle_a3: fullPrint,
  puzzle_a4: fullPrint,
  puzzle_photo_frame: colorOfMaterial,
  puzzle1000: fullPrint,
  puzzle408: fullPrint,
  rashguard: fullPrint,
  rolled_rectangle_sticker105x74: fullPrint,
  rolled_rectangle_sticker74x52: fullPrint,
  rolled_round_sticker50: fullPrint,
  rolled_square_sticker50x50: fullPrint,
  round_magnet50x50: fullPrint,
  round_mouse_pad: fullPrint,
  round_plastic_clock: {...round_plastic_clock, ...fullPrint},
  round_sticker50x50: material,
  round_sticker95x95: material,
  round_wood_clock: {...round_wood_clock, ...fullPrint},
  shopping_bag: guideLines,
  short_socks: fullPrint,
  shot: guideLines,
  shower_curtain: fullPrint,
  sleep_mask: fullPrint,
  sleeveless_dress: fullPrint,
  small_envelope: guideLines,
  small_pencil_case: fullPrint,
  snapback: guideLines,
  speaker: guideLines,
  sport_bottle: colorOfMaterial,
  square_keychain: fullPrint,
  square_magnet50x50: fullPrint,
  square_magnet95x95: fullPrint,
  square_pillow: colorOfMaterial,
  square_sticker50x50: material,
  square_sticker95x95: material,
  square_tablecloth: fullPrint,
  square_wood_clock: {...square_wood_clock, ...fullPrint},
  standard_pen: guideLines,
  star_magnet75x75: fullPrint,
  star_sticker75x75: material,
  star_sticker145x145: material,
  stone_photo_frame: fullPrint,
  stretch_tee: colorOfMaterial,
  suction_thermo_mug: colorOfMaterial,
  sweatshirt: colorOfMaterial,
  tanktop: colorOfMaterial,
  tapestry180x145: fullPrint,
  tee: colorOfMaterial,
  thermos: colorOfMaterial,
  thermo_mug: fullPrint,
  toothbrush: guideLines,
  towel30x30: guideLines,
  towel35x75: guideLines,
  towel50x90: guideLines,
  umbrella_unilateral: fullPrint,
  umbrella_bilateral: {...umbrella_bilateral, ...fullPrint},
  universal_hoodie: guideLines,
  universal_product: guideLines,
  windbreaker: colorOfMaterial,
  wineglass: guideLines,
  woman_bomber: colorOfMaterial,
  woman_hoodie: colorOfMaterial,
  woman_pajama_pants: fullPrint,
  woman_pants: colorOfMaterial,
  woman_sneakers: fullPrint,
  woman_suspenders: fullPrint,
  woman_tanktop: colorOfMaterial,
  woman_tee: colorOfMaterial,
  woman_underpants: fullPrint,
  women_cotton_sweatshirt: colorOfMaterial,
};
/* eslint-enable camelcase */
