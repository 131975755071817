export const example = {
  name: `color`, // for get image
  keyInProductMeta: `colors`, // for get value of variant from meta
  title: `Цвет изделия`,
  template: `Line`, // Column, Select, Checkbox, ColorPicker
  description: false,
  handleClick() {} // if you need custom handle
};

export const color = {
  name: `color`,
  keyInProductMeta: `colors`,
  title: `Цвет изделия`,
  template: `Line`,
  description: false
};

export const background = {
  name: `background`,
  title: `Цвет изделия`,
  template: `LineWithColorPicker`,
  description: false,
  colors: [
    `transparent`,
    `#000000`,
    `#ffffff`,
    `#9e9e9e`,
    `#ff0000`,
    `#ff6600`,
    `#ffff00`,
    `#00ff00`,
    `#00ffff`,
    `#0000ff`,
    `#8033ff`
  ]
};

export const foilType = {
  name: `color`,
  keyInProductMeta: `colors`,
  title: `Цвет фольги`,
  template: `Line`,
  description: false
};

export const materialType = {
  name: `material`,
  keyInProductMeta: `materials`,
  title: `Материал изделия`,
  template: `Line`,
  description: true
};

export const frameType = {
  name: `frame_type`,
  keyInProductMeta: `frame_types`,
  title: `Выбор рамки`,
  template: `Line`,
  description: true
};

export const lamination = {
  name: `lamination`,
  keyInProductMeta: `laminations`,
  title: `Ламинирование (цены за 100 шт.)`,
  template: `Select`,
  withPrice: true // need create price map for this variant
};

export const paperType = {
  name: `paper_type`,
  keyInProductMeta: `paper_types`,
  title: `Тип бумаги`,
  subTitle: `Цены за 100 шт. 1 сторона/2 стороны`,
  template: `Line`,
  description: true,
  withPrice: true
};

export const cornerRadius = {
  name: `corner_radius`,
  keyInProductMeta: `corner_radiuses`,
  title: `Закругленные края (4мм)`,
  template: `Checkbox`,
  withPrice: true
};
