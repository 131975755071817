class TransformCommand {
  constructor(receiver, options = {}) {
    this._initCallbacks(options);
    this.receiver = receiver;
    this._initStateProperties(options);

    this.state = {};
    this.prevState = {};

    if (this._isGroup(this.receiver)) {
      this.state.group = [];
      this.prevState.group = [];
      this.receiver.getObjects().forEach((_, i) => {
        this.state.group[i] = {};
        this.prevState.group[i] = {};
      });
    }

    this._saveState();
    this._savePrevState();
  }
  execute() {
    this._restoreState();
    this.receiver.setCoords();
  }
  undo() {
    this._restorePrevState();
    this.receiver.setCoords();
  }
  // private
  _initStateProperties(options) {
    this.stateProperties = this.receiver.stateProperties;
    if (options.stateProperties && options.stateProperties.length) {
      this.stateProperties.push(...options.stateProperties);
    }
  }
  _restoreState() {
    this._restore(this.state);
  }
  _restorePrevState() {
    this._restore(this.prevState);
  }
  _restore(state) {
    this.stateProperties.forEach((prop) => {
      this.receiver.set(prop, state[prop]);
      if (this._isGroup(this.receiver)) {
        this.receiver.getObjects().forEach((object, i) => {
          object.set(prop, state.group[i][prop]);
        });
      }
    });
    if (this._isGroup(this.receiver)) {
      this.receiver.getObjects().forEach((object) => {
        object.set(`group`, this.receiver);
      });
    }
  }
  _initCallbacks(options) {
    if (typeof options.onBeforeUndo === `function`) {
      this.onBeforeUndo = options.onBeforeUndo;
    }
    if (typeof options.onAfterUndo === `function`) {
      this.onAfterUndo = options.onAfterUndo;
    }
  }
  _saveState() {
    this.stateProperties.forEach((prop) => {
      this.state[prop] = this.receiver.get(prop);
      if (this._isGroup(this.receiver)) {
        this.receiver.getObjects().forEach((object, i) => {
          if (object && object.get(prop)) {
            this.state.group[i][prop] = object.get(prop);
          }
        });
      }
    });
  }
  _savePrevState() {
    if (this.receiver._stateProperties) {
      this.stateProperties.forEach((prop) => {
        this.prevState[prop] = this.receiver._stateProperties[prop];
        if (this._isGroup(this.receiver)) {
          this.receiver.getObjects().forEach((object, i) => {
            if (object._stateProperties && object._stateProperties[prop]) {
              this.prevState.group[i][prop] = object._stateProperties[prop];
            }
          });
        }
      });
    }
  }
  _isGroup(object) {
    return object.isType(`activeSelection`) || object.isType(`group`);
  }
}

export default TransformCommand;
