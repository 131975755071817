import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class Error extends Component {
  static propTypes = {
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  }
  static defaultProps = {
    message: `Попробуйте перезагрузить страницу`,
  }
  render() {
    const { message } = this.props
    return (
      <div className="error">
        <div className="error__title">Произошла ошибка</div>
        <div className="error__content">
          {Array.isArray(message)
            ? message.map((text, i) => <div key={i}>{text}</div>)
            : message}
        </div>
      </div>
    )
  }
}
