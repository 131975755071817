class ObjectResizer {
  VECTOR_TYPES = [
    `application/pdf`,
    `application/svg`,
    `image/svg+xml`,
    `application/postscript`,
    `application/octet-stream`
  ];
  SCALE_MARGIN = 0;
  setOptions(options) {
    const [top, left, width, height] = options.dimensions;
    this.dimensions = {top, left, width, height};
    this.dpi = options.dpi;
  }
  centerObject(object) {
    const dim = this.dimensions;
    const offset = object.originY === `top` ? -object.getScaledHeight() / 2 : 0;

    object.set({
      left: dim.width / 2 + dim.left,
      top: dim.height / 2 + dim.top + offset
    });
  }
  resizeToFill(object) {
    this.centerObject(object);
    if (object.width) {
      object.scaleToWidth(this.getCurrentSideWidth() - this.SCALE_MARGIN);
    }
    if (
      object.getScaledHeight() < this.getCurrentSideHeight() &&
      object.height
    ) {
      object.scaleToHeight(this.getCurrentSideHeight() - this.SCALE_MARGIN);
    }
    return this;
  }
  isPath(object) {
    return (
      object.isType(`path`) ||
      object.isType(`circle`) ||
      object.isType(`rect`) ||
      object.isType(`ellipse`) ||
      object.isType(`polygon`) ||
      object.isType(`line`) ||
      object.isType(`polyline`)
    );
  }
  isPathGroup(object) {
    return (
      object.getObjects &&
      object
        .getObjects()
        .every(
          (obj) =>
            obj.type === `path` ||
            obj.type === `circle` ||
            obj.type === `rect` ||
            obj.type === `ellipse` ||
            obj.type === `polygon` ||
            obj.type === `line` ||
            obj.type === `polyline`
        )
    );
  }
  isVector(object) {
    return (
      this.VECTOR_TYPES.indexOf(object.imageType) > -1 ||
      this.isPathGroup(object) ||
      this.isPath(object)
    );
  }
  resizeToFit(object, originalWidth, originalHeight) {
    // console.table([
    //   ['originalWidth', originalWidth],
    //   ['originalHeight', originalHeight],
    //   ['dpi', this.dpi],
    //   ['dimensions', JSON.stringify(this.dimensions)]
    // ]);
    this.centerObject(object);
    if (object.isType(`image`) && !this.isVector(object)) {
      this.resizeObjectPerDPI(object, originalWidth, originalHeight);
      // only resize group per DPI if it has any objects with originalWidth (i.e. images)
      this.resizeObjectPerDPI(object);
    } else if (this.isVector(object)) {
      if (object.width) {
        object.scaleToWidth(this.getCurrentSideWidth());
      }
      if (
        object.height &&
        object.getScaledHeight() > this.getCurrentSideHeight()
      ) {
        object.scaleToHeight(this.getCurrentSideHeight());
      }
      const printingAreaWidth = this.getCurrentSideWidth();
      const printingAreaHeight = this.getCurrentSideHeight();
      const printingAreaRatio = printingAreaWidth / printingAreaHeight;
      const designRatio = object.getScaledWidth() / object.getScaledHeight();

      if (printingAreaRatio < designRatio) {
        object.scaleToWidth(printingAreaWidth);
      } else {
        object.scaleToHeight(printingAreaHeight);
      }
    }
    // if (typeof __canvas !== `undefined`) {
    //   __canvas.setActiveObject(object.setCoords());
    // }
    return this;
  }
  getHeightForCurrentSideDPI(object, requestedDPI, currentSideDPI) {
    const originalHeight = object.isType(`group`)
      ? object.getMinOriginalHeight()
      : object.originalHeight;

    return (originalHeight / requestedDPI) * currentSideDPI;
  }
  getWidthForCurrentSideDPI(object, requestedDPI, currentSideDPI) {
    const originalWidth = object.isType(`group`)
      ? object.getMinOriginalWidth()
      : object.originalWidth;

    return (originalWidth / requestedDPI) * currentSideDPI;
  }
  getCurrentSideWidth() {
    return this.dimensions.width;
  }
  getCurrentSideHeight() {
    return this.dimensions.height;
  }
  findBestUsableHeight(object) {
    return this._findBestUsable(`Height`, object);
  }
  findBestUsableWidth(object) {
    return this._findBestUsable(`Width`, object);
  }
  _findBestUsable(side, object) {
    const maxDPI = 200;
    const minDPI = 100;
    const DPIInterval = 50;
    const minAllowed = 20;
    const currentSideDPI = this.dpi;
    const methodName = `get` + side + `ForCurrentSideDPI`;

    for (let i = maxDPI; i >= minDPI; i -= DPIInterval) {
      const value = this[methodName](object, i, currentSideDPI);
      if (value > minAllowed) {
        return value;
      }
    }
    return this[methodName](object, minDPI, currentSideDPI);
  }
  findSideToScaleBy() {
    return this.getCurrentSideWidth() > this.getCurrentSideHeight()
      ? `Height`
      : `Width`;
  }
  resizeObjectPerDPI(object, originalWidth, originalHeight) {
    if (originalWidth && originalHeight) {
      object.set({
        originalWidth: parseInt(originalWidth, 10),
        originalHeight: parseInt(originalHeight, 10)
      });
    } else {
      originalWidth = object.originalWidth;
      originalHeight = object.originalHeight;
    }
    if (object.isType(`group`)) {
      originalWidth = object.getMinOriginalWidth();
      originalHeight = object.getMinOriginalHeight();
    }
    const sideToScaleBy = this.findSideToScaleBy(object);
    let bestUsableConstraint = this[`findBestUsable` + sideToScaleBy](object);
    let canvasConstraint = this[`getCurrentSide` + sideToScaleBy]();

    object[`scaleTo` + sideToScaleBy](
      Math.min(bestUsableConstraint, canvasConstraint)
    );

    if (object.getScaledHeight() > this.getCurrentSideHeight()) {
      bestUsableConstraint = this.findBestUsableHeight(object);
      canvasConstraint = this.getCurrentSideHeight();

      object.scaleToHeight(Math.min(bestUsableConstraint, canvasConstraint));
    }
    if (object.getScaledWidth() > this.getCurrentSideWidth()) {
      bestUsableConstraint = this.findBestUsableWidth(object);
      canvasConstraint = this.getCurrentSideWidth();

      object.scaleToWidth(Math.min(bestUsableConstraint, canvasConstraint));
    }
    if (object.canvas) {
      object.canvas.renderAll();
    }
  }
  isBelowMinDPI(object) {
    if (this.isVector(object)) {
      return false;
    }
    const minDPI = 100;
    const currentSideDPI = this.dpi;
    const maxAllowedHeight = this.getHeightForCurrentSideDPI(
      object,
      minDPI,
      currentSideDPI
    );
    const maxAllowedWidth = this.getWidthForCurrentSideDPI(
      object,
      minDPI,
      currentSideDPI
    );
    return (
      object.getScaledHeight() > maxAllowedHeight ||
      object.getScaledWidth() > maxAllowedWidth
    );
  }
}

export default new ObjectResizer();
