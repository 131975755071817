class CreateSelectionCommand {
  constructor(receiver, controller) {
    this.receiver = receiver;
    this.controller = controller;
  }
  execute() {
    this.controller.selectObjects(this.receiver);
  }
  undo() {
    this.controller.unSelectObjects(this.receiver);
  }
}

export default CreateSelectionCommand;
