import {
  color,
  materialType,
  foilType,
  frameType,
  paperType,
  lamination,
  cornerRadius,
  background
} from "./variants";

export const guideLines = {
  guideLines: true
};

export const chocolate = {
  variants: [foilType],
  guideLines: true
};

export const colorOfMaterial = {
  variants: [color],
  guideLines: true
};

export const material = {
  variants: [materialType]
};

export const canvas = {
  variants: [frameType]
};

export const cards = {
  variants: [paperType, lamination, cornerRadius],
  guideLines: 22.1
};

export const poster = {
  variants: [background],
  fullPrint: true,
  guideLines: 22.5
};

export const fullPrint = {
  variants: [background],
  fullPrint: true
};

export const mousePad = {
  variants: [background],
  fullPrint: true,
  guideLines: 22.1
};
