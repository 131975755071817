const extendFabricFilters = () => {
  const fabric = window && window.fabric;
  fabric.Image.filters.Waterize = fabric.util.createClass(
    fabric.Image.filters.BaseFilter,
    {
      type: `Waterize`,
      initialize(options = {}) {
        this.amplitude = options.amplitude || 14;
        this.frequency = options.frequency || 0.6;
        this.frames = options.frames || 0;
      },
      applyTo2d(options) {
        const imageData = options.imageData;
        const data = imageData.data;
        const height = imageData.height;
        const width = imageData.width;
        const amplitude = this.amplitude;
        const T = this.frames * this.frequency * 0.016;
        const stride = width * 4;
        const twoPI = 2 * Math.PI;
        const maxY = height - amplitude;
        const maxX = width - amplitude;
        let len = 4 * height * width;
        let x;
        let y;
        let xs;
        let ys;
        let dest;
        let src;

        const pixels = new Array(len);
        while (len--) {
          pixels[len] = data[len];
        }

        for (x = amplitude; x < maxX; ++x) {
          ys = Math.round(amplitude * Math.cos(twoPI * ((3 * x) / width + T)));

          for (y = amplitude; y < maxY; ++y) {
            xs = Math.round(
              amplitude * Math.sin(twoPI * ((3 * y) / height + T))
            );

            dest = y * stride + x * 4;
            src = (y + ys) * stride + (x + xs) * 4;

            data[dest] = pixels[src] || 0;
            data[dest + 1] = pixels[src + 1] || 0;
            data[dest + 2] = pixels[src + 2] || 0;
          }
        }
      },

      toObject() {
        return fabric.util.object.extend(this.callSuper(`toObject`), {
          amplitude: this.amplitude,
          frequency: this.frequency
        });
      }
    }
  );

  fabric.Image.filters.Waterize.fromObject =
    fabric.Image.filters.BaseFilter.fromObject;

  fabric.Image.filters.RemoveBlack = fabric.util.createClass(
    fabric.Image.filters.BaseFilter,
    {
      type: `RemoveBlack`,
      initialize(options = {}) {
        this.threshold = options.threshold || 30;
        this.distance = options.distance || 20;
      },
      applyTo2d(options) {
        const imageData = options.imageData;
        const data = imageData.data;
        const threshold = this.threshold;
        const distance = this.distance;
        const abs = Math.abs;
        let r;
        let g;
        let b;

        for (let i = 0, len = data.length; i < len; i += 4) {
          r = data[i];
          g = data[i + 1];
          b = data[i + 2];

          if (
            r < threshold &&
            g < threshold &&
            b < threshold &&
            abs(r - g) < distance &&
            abs(r - b) < distance &&
            abs(g - b) < distance
          ) {
            data[i + 3] = 1;
          }
        }
      },
      toObject() {
        return fabric.util.object.extend(this.callSuper(`toObject`), {
          threshold: this.threshold,
          distance: this.distance
        });
      }
    }
  );

  fabric.Image.filters.RemoveBlack.fromObject =
    fabric.Image.filters.BaseFilter.fromObject;

  fabric.Image.filters.RemoveWhite = fabric.util.createClass(
    fabric.Image.filters.BaseFilter,
    {
      type: `RemoveWhite`,
      initialize(options) {
        options = options || {};
        this.threshold = options.threshold || 30;
        this.distance = options.distance || 20;
      },
      applyTo2d(options) {
        const imageData = options.imageData;
        const data = imageData.data;
        const threshold = this.threshold;
        const distance = this.distance;
        const limit = 255 - threshold;
        const abs = Math.abs;
        let r;
        let g;
        let b;

        for (let i = 0, len = data.length; i < len; i += 4) {
          r = data[i];
          g = data[i + 1];
          b = data[i + 2];

          if (
            r > limit &&
            g > limit &&
            b > limit &&
            abs(r - g) < distance &&
            abs(r - b) < distance &&
            abs(g - b) < distance
          ) {
            data[i + 3] = 1;
          }
        }
      },
      toObject() {
        return fabric.util.object.extend(this.callSuper(`toObject`), {
          threshold: this.threshold,
          distance: this.distance
        });
      }
    }
  );

  fabric.Image.filters.RemoveWhite.fromObject =
    fabric.Image.filters.BaseFilter.fromObject;

  fabric.Image.filters.Sepia2 = fabric.util.createClass(
    fabric.Image.filters.BaseFilter,
    {
      type: `Sepia2`,
      applyTo2d(options) {
        const imageData = options.imageData;
        const data = imageData.data;
        const iLen = data.length;
        let r;
        let g;
        let b;

        for (let i = 0; i < iLen; i += 4) {
          r = data[i];
          g = data[i + 1];
          b = data[i + 2];

          data[i] = (r * 0.393 + g * 0.769 + b * 0.189) / 1.351;
          data[i + 1] = (r * 0.349 + g * 0.686 + b * 0.168) / 1.203;
          data[i + 2] = (r * 0.272 + g * 0.534 + b * 0.131) / 2.14;
        }
      }
    }
  );

  fabric.Image.filters.Sepia2.fromObject =
    fabric.Image.filters.BaseFilter.fromObject;
};

export default extendFabricFilters;
