export default {
  permissions: {
    outside: {
      text: false,
      image: false,
      background: true,
      drawControls: false,
    },
  },
}
