export default {
  objectToLoad: {
    front: {
      path: `/shapes/svg/single_calendar_2025.svg`,
      left: 300,
      top: 500,
      scaleX: 0.33,
      scaleY: 0.33
    }
  },
  loadedObjectSettings: {
    title: `Цвета календарной сетки`,
    colorPickers: [
      {
        label: `Будни`,
        pathNumber: 0
      },
      {
        label: `Выходные`,
        pathNumber: 1
      }
    ]
  }
};
