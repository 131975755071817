import * as THREE from "three";
import Mediator from "../../components/EditorApp/services/Mediator";
import {CANVAS} from "../../components/EditorApp/constants";

// The SceneSubject is responsible for:

// Creating the subject with material and textures

export default class SceneSubject {
  constructor(scene, settings) {
    this.scene = scene;
    this.settings = settings;
  }

  async applySettings(settings) {
    this.settings = settings;
    try {
      const withDesign = true;
      await this._addModelToScene(this.settings, withDesign);
      if (this.settings.additional) {
        const withoutDesign = false;
        await this._addModelToScene(this.settings.additional, withoutDesign);
      }
    } catch (err) {
      throw err;
    }
  }
  // private methods
  async _addModelToScene({object, texture, ...settings}, withDesign) {
    const modelTexture = await this._getTexture(texture, withDesign);
    const model = await this._getModel(object);
    if (modelTexture) {
      const material = new THREE.MeshPhongMaterial({
        map: modelTexture,
        shininess: 0,
        ambient: 0xffffff,
        color: 0xffffff,
        specular: 0xffffff
      });
      this._applyMaterialToModel(model, material);
    }
    this._applyModelSettings(model, settings);
    this.scene.add(model);
  }
  _getModel(object) {
    const objectLoader = new THREE.ObjectLoader();
    return new Promise((resolve, reject) => {
      const onLoad = (obj) => {
        resolve(obj);
      };
      // const onProgress = (xhr) => {
      //   console.log(`model ${(xhr.loaded / xhr.total) * 100}% loaded`);
      // };
      const onError = (err) => {
        reject(err);
      };
      objectLoader.load(object, onLoad, null, onError);
    });
  }
  async _getTexture(texture, withDesign) {
    const originalTexture = await this._getOriginalTexture(texture);
    if (withDesign) {
      const imagesOfDesign = this.settings.designs
        ? await this._getImagesOfDesignFromData()
        : await this._getImagesOfDesignFromUrl();
      const combineImage = await this._addDesignsToOriginalTexture(
        imagesOfDesign,
        originalTexture
      );
      return await this._getTextureFromUrl(combineImage.src);
    }
    if (originalTexture) {
      return await this._getTextureFromUrl(originalTexture.src);
    }
    return null;
  }
  async _addDesignsToOriginalTexture(designImages, textureImage) {
    const tmpCanvas = new window.fabric.Canvas();
    const {width, height} = textureImage;
    tmpCanvas.setDimensions({
      width,
      height
    });

    const fabricImages = [
      new window.fabric.Image(textureImage, {
        originX: `left`,
        originY: `top`,
        top: 0,
        left: 0
      }),
      ...designImages.map(({image}, i) => {
        const [left, top] =
          this.settings.textureOffset[i] &&
          this.settings.textureOffset[i] !== 0 &&
          Array.isArray(this.settings.textureOffset[i])
            ? this.settings.textureOffset[i]
            : this.settings.textureOffset;

        return new window.fabric.Image(image, {
          originX: `left`,
          originY: `top`,
          top,
          left,
          scaleX: this.settings.designs ? 1 : this.settings.textureScale,
          scaleY: this.settings.designs ? 1 : this.settings.textureScale
        });
      })
    ];

    fabricImages.forEach((image) => {
      tmpCanvas.add(image);
    });

    return this._createImageFromUrl(
      tmpCanvas.toDataURL({
        format: `png`,
        multiplier: 1,
        width,
        height
      })
    );
  }
  async _getImagesOfDesignFromData() {
    const designs = Object.values(this.settings.designs).map((item) => ({
      side: item.side,
      data: item.design,
      dimensions: item.dimensions
    }));

    const promises = designs.map(
      ({side, data, dimensions}) =>
        new Promise(async (resolve) => {
          resolve({
            side,
            image: await this._transformDesignDataToImage(
              {data, dimensions},
              this.settings.textureScale
            )
          });
        })
    );

    return await Promise.all(promises);
  }
  async _transformDesignDataToImage({data, dimensions}, scale) {
    const [top, left, width, height] = dimensions;
    const tmpCanvas = new window.fabric.Canvas();
    tmpCanvas.setDimensions({
      width: CANVAS.WIDTH * scale,
      height: CANVAS.HEIGHT * scale
    });
    await Mediator.applyDesignToCanvas(data, tmpCanvas);

    return await this._createImageFromUrl(
      tmpCanvas.toDataURL({
        format: `png`,
        multiplier: scale,
        top,
        left,
        width,
        height
      })
    );
  }
  async _getImagesOfDesignFromUrl() {
    const {arts} = this.settings;
    const promises = Object.keys(arts).map(
      (side) =>
        new Promise(async (resolve) => {
          resolve({
            side,
            image: await this._createImageFromUrl(arts[side])
          });
        })
    );

    return await Promise.all(promises);
  }
  async _getOriginalTexture(texture) {
    if (!texture) {
      return null;
    }
    const {
      condition: {variants}
    } = this.settings;
    if (typeof texture === `string`) {
      return await this._createImageFromUrl(texture);
    }
    const variant =
      variants[`color`] || variants[`frame_type`] || variants[`paper_type`];
    if (texture[variant]) {
      return await this._createImageFromUrl(texture[variant]);
    }
    return null;
  }
  _createImageFromUrl(url) {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.crossOrigin = `anonymous`;
      image.src = url;

      image.onload = () => {
        resolve(image);
        image.onload = null;
      };
      image.onerror = reject;
    });
  }
  _getTextureFromUrl(url) {
    return new Promise((resolve, reject) => {
      const onLoad = (obj) => {
        resolve(obj);
      };
      // const onProgress = (xhr) => {
      //   console.log(`texture ${(xhr.loaded / xhr.total) * 100}% loaded`);
      // };
      const onError = (err) => {
        reject(err);
      };
      new THREE.TextureLoader().load(url, onLoad, null, onError);
    });
  }
  _applyModelSettings(model, settings) {
    if (settings) {
      const {rotation = [0, 0, 0], position = [0, 0, 0]} = settings;
      model.rotation.set(...rotation);
      model.position.set(...position);
    }
  }
  _applyMaterialToModel(model, material) {
    model.children[0].material = material;
  }
}
