export default (effectName) => {
  switch (effectName) {
    case `removeWhite`:
      return {
        id: 0,
        makeTitle: `Remove white`,
        undoTitle: `Restore white`,
        value: new window.fabric.Image.filters.RemoveColor({
          distance: 0.15
        })
      };
    case `removeBlack`:
      return {
        id: 9,
        makeTitle: `Remove black`,
        undoTitle: `Restore black`,
        value: new window.fabric.Image.filters.RemoveColor({
          color: `#000`,
          distance: 0.15
        })
      };
    case `grayscale`:
      return {
        id: 1,
        makeTitle: `Make grayscale`,
        undoTitle: `Undo grayscale`,
        value: new window.fabric.Image.filters.Grayscale()
      };
    case `invert`:
      return {
        id: 2,
        makeTitle: `Make inverted`,
        undoTitle: `Undo inverted`,
        value: new window.fabric.Image.filters.Invert()
      };
    case `sepia`:
      return {
        id: 3,
        makeTitle: `Add sepia`,
        undoTitle: `Undo sepia`,
        value: new window.fabric.Image.filters.Sepia()
      };
    case `brightness`:
      return {
        id: 4,
        makeTitle: `Add brightness`,
        undoTitle: `Remove brightenss`,
        value: new window.fabric.Image.filters.Brightness({brightness: 0.2})
      };
    case `blur`:
      return {
        id: 5,
        makeTitle: `Add blur`,
        undoTitle: `Remove blur`,
        value: new window.fabric.Image.filters.Convolute({
          matrix: [
            1 / 9,
            1 / 9,
            1 / 9,
            1 / 9,
            1 / 9,
            1 / 9,
            1 / 9,
            1 / 9,
            1 / 9
          ]
        })
      };
    case `sharpen`:
      return {
        id: 6,
        makeTitle: `Add sharpen`,
        undoTitle: `Remove sharpen`,
        value: new window.fabric.Image.filters.Convolute({
          matrix: [0, -1, 0, -1, 5, -1, 0, -1, 0]
        })
      };
    case `emboss`:
      return {
        id: 7,
        makeTitle: `Undo emboss`,
        undoTitle: `Remove sharpen`,
        value: new window.fabric.Image.filters.Convolute({
          matrix: [1, 1, 1, 1, 0.7, -1, -1, -1, -1]
        })
      };
    case `noise`:
      return {
        id: 8,
        makeTitle: `Add noise`,
        undoTitle: `Undo noise`,
        value: new window.fabric.Image.filters.Noise({noise: 75})
      };
    case `pixelate`:
      return {
        id: 10,
        makeTitle: `Pixelate`,
        undoTitle: `Undo pixelate`,
        value: new window.fabric.Image.filters.Pixelate({blocksize: 10})
      };
    case `waterize`:
      return {
        id: 11,
        makeTitle: `Waterize`,
        undoTitle: `Undo waterize`,
        value: new window.fabric.Image.filters.Waterize()
      };
    case `round`:
      return {
        id: `Circle`,
        value(object) {
          const {width, height} = object;
          const widthOrHeight = width < height ? width : height;

          return new window.fabric.Circle({
            radius: widthOrHeight / 2,
            top: 0,
            left: 0
          });
        }
      };
    case `corner`:
      return {
        id: `Rect`,
        value(object) {
          const {width, height} = object;
          const radius = (width > height ? width : height) / 12;

          return new window.fabric.Rect({
            rx: radius,
            ry: radius,
            left: 0,
            top: 0,
            width,
            height
          });
        }
      };
  }
  return void 0;
};
