class AddCommand {
  constructor(receiver, controller) {
    this.receiver = receiver;
    this.controller = controller;
  }
  execute() {
    this.controller.addObject(this.receiver);
  }
  undo() {
    this.controller.removeObject(this.receiver);
  }
}

export default AddCommand;
