import {imagesApi, contributorsApi} from "../../../api/shutterstock";

const API_PREFIX = `api/editor`;
export const API_BACKGROUNDS = `/${API_PREFIX}/background_urls`;
export const API_PRODUCT_INFO = `/${API_PREFIX}`;
export const API_REALISM = `/${API_PREFIX}/product_renders`;
export const API_IMAGES = `/${API_PREFIX}/images`;
export const API_IMAGE_FILTERS = `/${API_PREFIX}/image_filters`;

export const apiGoogleSearchTemplate = ({
  imgSize = `xlarge`,
  start = 1,
  value = ``,
  type = ``,
  color = ``
}) => {
  return [
    `https://www.googleapis.com/customsearch/v1?`,
    `key=AIzaSyAn2wqF7Lwa58X_RJbHXqtITzebbXWgcWI&cx=001658083293066718026:58fitlgbutk&`,
    `searchType=image&imgSize=${imgSize}&start=${start}&q=${value}${type}${color}`
  ].join(``);
};

export const shutterStockSearch = (query) => imagesApi.searchImages(query);
export const shutterStockRetrieveContributor = (id) =>
  contributorsApi.getContributor(id);
