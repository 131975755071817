export const CHANGE_LOCATION = '@@router/LOCATION_CHANGE'
export const LOAD_DATA = 'LOAD_DATA'

export const START = '_START'
export const SUCCESS = '_SUCCESS'
export const FAIL = '_FAIL'

export const STORE = 'STORE_'
export const ITEM = 'ITEM_'

export const SMALL = 'small'
export const TILE = 'tile'
export const TEASER = 'teaser'

export const STORE_LOAD_DATA_SUCCESS = STORE + LOAD_DATA + SUCCESS
