export default {
  objectToLoad: {
    front: {
      path: `/shapes/svg/square_wood_clock.svg`,
      left: 300,
      top: 300,
      scaleX: 0.55,
      scaleY: 0.55
    }
  },
  loadedObjectSettings: {
    title: `Цвет циферблата`,
    colorPickers: [
      {
        label: ``,
        pathNumber: 0
      }
    ]
  }
};
