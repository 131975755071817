const extendFabric = () => {
  function checkClick(e, value) {
    return `which` in e ? e.which === value : e.button === value - 1;
  }
  const RIGHT_CLICK = 3;
  const MIDDLE_CLICK = 2;
  const LEFT_CLICK = 1;
  const addEventOptions = {passive: false};
  const fabric = window && window.fabric;
  fabric.PathGroup = {};
  fabric.PathGroup.fromObject = function (object, callback) {
    if (typeof object.paths === `string`) {
      fabric.loadSVGFromURL(object.paths, function (elements) {
        const pathUrl = object.paths;
        delete object.paths;

        // hack for grouping
        if (elements.length === 1) {
          elements.push(new fabric.Path(`MD`));
        }

        const pathGroup = fabric.util.groupSVGElements(
          elements,
          object,
          pathUrl
        );
        if (pathGroup.hasOwnProperty(`fill`)) {
          if (pathGroup._objects) {
            let i = pathGroup._objects.length;
            while (i--) {
              pathGroup._objects[i][`fill`] = pathGroup.fill;
            }
          }
        }

        pathGroup.type = `group`;

        callback(pathGroup);
      });
    } else {
      fabric.util.enlivenObjects(object.paths, function (enlivenedObjects) {
        delete object.paths;
        callback(new fabric.Group(enlivenedObjects, object));
      });
    }
  };

  // don't fire selection event when object removed
  fabric.Canvas.prototype._onObjectRemoved = function (obj) {
    if (this._hoveredTarget === obj) {
      this._hoveredTarget = null;
    }
    // this.callSuper(`_onObjectRemoved`, obj);
  };
  fabric.Canvas.prototype.discardActiveObject = function (e) {
    const currentActives = this.getActiveObjects();
    const currentActive = this.getActiveObject();
    if (currentActive) {
      this.fire(`before:selection:cleared`, {target: currentActive, e});
    }
    this._discardActiveObject(e);
    this._fireSelectionEvents(currentActives, e);
    return this;
  };

  let touchDidMoved = false;

  fabric.Canvas.prototype.__onMouseMove = function (e) {
    touchDidMoved = true;
    this._handleEvent(e, `move:before`);
    this._cacheTransformEventData(e);
    let target;
    let pointer;

    if (this.isDrawingMode) {
      this._onMouseMoveInDrawingMode(e);
      return;
    }
    if (typeof e.touches !== `undefined` && e.touches.length > 1) {
      return;
    }

    let groupSelector = this._groupSelector;

    // We initially clicked in an empty area, so we draw a box for multiple selection
    if (groupSelector) {
      pointer = this._pointer;

      groupSelector.left = pointer.x - groupSelector.ex;
      groupSelector.top = pointer.y - groupSelector.ey;

      this.renderTop();
    } else if (!this._currentTransform) {
      target = this.findTarget(e) || null;
      this._setCursorFromEvent(e, target);
      this._fireOverOutEvents(target, e);
    } else {
      this._transformObject(e);
    }
    this._handleEvent(e, `move`);
    this._resetTransformEventData();
  };

  fabric.Canvas.prototype.__onMouseUp = function (e) {
    let target;

    let transform = this._currentTransform;

    let groupSelector = this._groupSelector;

    let shouldRender = false;

    let isClick =
      !groupSelector || (groupSelector.left === 0 && groupSelector.top === 0);
    this._cacheTransformEventData(e);
    target = this._target;
    this._handleEvent(e, `up:before`);
    // if right/middle click just fire events and return
    // target undefined will make the _handleEvent search the target
    if (checkClick(e, RIGHT_CLICK)) {
      if (this.fireRightClick) {
        this._handleEvent(e, `up`, RIGHT_CLICK, isClick);
      }
      return;
    }

    if (checkClick(e, MIDDLE_CLICK)) {
      if (this.fireMiddleClick) {
        this._handleEvent(e, `up`, MIDDLE_CLICK, isClick);
      }
      this._resetTransformEventData();
      return;
    }

    if (this.isDrawingMode && this._isCurrentlyDrawing) {
      this._onMouseUpInDrawingMode(e);
      return;
    }

    if (transform) {
      this._finalizeCurrentTransform(e);
      shouldRender = transform.actionPerformed;
    }
    if (
      this._shouldClearSelection(e, target) &&
      !this.selection &&
      !touchDidMoved
    ) {
      this.discardActiveObject(e);
    }

    if (!isClick) {
      this._maybeGroupObjects(e);
      if (!shouldRender) {
        shouldRender = this._shouldRender(target);
      }
    }
    if (target) {
      target.isMoving = false;
    }
    this._setCursorFromEvent(e, target);
    this._handleEvent(e, `up`, LEFT_CLICK, isClick);
    this._groupSelector = null;
    this._currentTransform = null;
    // reset the target information about which corner is selected
    if (target) {
      target.__corner = 0;
    }
    if (shouldRender) {
      this.requestRenderAll();
    } else if (!isClick) {
      this.renderTop();
    }
  };

  fabric.Canvas.prototype.__onMouseDown = function (e) {
    touchDidMoved = false;
    this._cacheTransformEventData(e);
    this._handleEvent(e, `down:before`);
    let target = this._target;
    // if right click just fire events
    if (checkClick(e, RIGHT_CLICK)) {
      if (this.fireRightClick) {
        this._handleEvent(e, `down`, RIGHT_CLICK);
      }
      return;
    }

    if (checkClick(e, MIDDLE_CLICK)) {
      if (this.fireMiddleClick) {
        this._handleEvent(e, `down`, MIDDLE_CLICK);
      }
      return;
    }

    if (this.isDrawingMode) {
      this._onMouseDownInDrawingMode(e);
      return;
    }

    // ignore if some object is being transformed at this moment
    if (this._currentTransform) {
      return;
    }

    let pointer = this._pointer;
    // save pointer for check in __onMouseUp event
    this._previousPointer = pointer;
    let shouldRender = this._shouldRender(target);

    let shouldGroup = this._shouldGroup(e, target);
    if (this._shouldClearSelection(e, target) && this.selection) {
      this.discardActiveObject(e);
    } else if (shouldGroup) {
      this._handleGrouping(e, target);
      target = this._activeObject;
    }
    if (
      this.selection &&
      (!target ||
        (!target.selectable &&
          !target.isEditing &&
          target !== this._activeObject))
    ) {
      this._groupSelector = {
        ex: pointer.x,
        ey: pointer.y,
        top: 0,
        left: 0
      };
    }

    if (target) {
      let alreadySelected = target === this._activeObject;
      if (target.selectable) {
        this.setActiveObject(target, e);
      }
      if (target === this._activeObject && (target.__corner || !shouldGroup)) {
        this._setupCurrentTransform(e, target, alreadySelected);
      }
    }
    this._handleEvent(e, `down`);
    // we must renderAll so that we update the visuals
    if (shouldRender || shouldGroup) {
      this.requestRenderAll();
    }
  };

  fabric.Canvas.prototype._onTouchStart = function (e) {
    if (!this.allowTouchScrolling && e.preventDefault) {
      e.preventDefault();
    }
    if (this.mainTouchId === null) {
      this.mainTouchId = this.getPointerId(e);
    }
    this.__onMouseDown(e);
    this._resetTransformEventData();
    let canvasElement = this.upperCanvasEl;
    let eventTypePrefix = this._getEventPrefix();
    fabric.util.addListener(
      fabric.document,
      `touchend`,
      this._onTouchEnd,
      addEventOptions
    );
    fabric.util.addListener(
      fabric.document,
      `touchmove`,
      this._onMouseMove,
      addEventOptions
    );
    // Unbind mousedown to prevent double triggers from touch devices
    fabric.util.removeListener(
      canvasElement,
      eventTypePrefix + `down`,
      this._onMouseDown
    );
  };

  // custom rotate

  const rotateIcon = new Image();
  rotateIcon.src = `data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxMCAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMi42NDU4OSAyLjY0NTg1QzMuMjUwMDYgMi4wNDE2OSA0LjA3OTIyIDEuNjY2NjkgNS4wMDAwNiAxLjY2NjY5QzYuODQxNzIgMS42NjY2OSA4LjMyOTIyIDMuMTU4MzUgOC4zMjkyMiA1LjAwMDAyQzguMzI5MjIgNi44NDE2OSA2Ljg0MTcyIDguMzMzMzYgNS4wMDAwNiA4LjMzMzM2QzMuNDQ1ODkgOC4zMzMzNSAyLjE1MDA2IDcuMjcwODUgMS43NzkyMiA1LjgzMzM1TDIuNjQ1ODkgNS44MzMzNUMyLjk4NzU2IDYuODA0MTkgMy45MTI1NiA3LjUwMDAyIDUuMDAwMDYgNy41MDAwMkM2LjM3OTIyIDcuNTAwMDIgNy41MDAwNiA2LjM3OTE5IDcuNTAwMDYgNS4wMDAwMkM3LjUwMDA2IDMuNjIwODUgNi4zNzkyMiAyLjUwMDAyIDUuMDAwMDYgMi41MDAwMkM0LjMwODM5IDIuNTAwMDIgMy42OTE3MiAyLjc4NzUyIDMuMjQxNzIgMy4yNDE2OUw0LjU4MzM5IDQuNTgzMzVMMS42NjY3MiA0LjU4MzM1TDEuNjY2NzIgMS42NjY2OUwyLjY0NTg5IDIuNjQ1ODVaIiBmaWxsPSJ3aGl0ZSIvPjwvc3ZnPg==`;

  fabric.Object.prototype.drawControls = function (ctx, styleOverride) {
    styleOverride = styleOverride || {};
    const wh = this._calculateCurrentDimensions();
    const width = wh.x;
    const height = wh.y;
    const scaleOffset = styleOverride.cornerSize || this.cornerSize;
    const left = -(width + scaleOffset) / 2;
    const top = -(height + scaleOffset) / 2;
    const transparentCorners =
      typeof styleOverride.transparentCorners !== `undefined`
        ? styleOverride.transparentCorners
        : this.transparentCorners;
    const hasRotatingPoint =
      typeof styleOverride.hasRotatingPoint !== `undefined`
        ? styleOverride.hasRotatingPoint
        : this.hasRotatingPoint;
    const methodName = transparentCorners ? `stroke` : `fill`;

    ctx.save();
    ctx.strokeStyle = ctx.fillStyle =
      styleOverride.cornerColor || this.cornerColor;
    if (!this.transparentCorners) {
      ctx.strokeStyle =
        styleOverride.cornerStrokeColor || this.cornerStrokeColor;
    }
    this._setLineDash(
      ctx,
      styleOverride.cornerDashArray || this.cornerDashArray,
      null
    );
    // top-left
    this._drawControl(`tl`, ctx, methodName, left, top, styleOverride);
    // top-right
    this._drawControl(`tr`, ctx, methodName, left + width, top, styleOverride);
    // bottom-left
    this._drawControl(`bl`, ctx, methodName, left, top + height, styleOverride);
    // bottom-right
    this._drawControl(
      `br`,
      ctx,
      methodName,
      left + width,
      top + height,
      styleOverride
    );
    if (!this.get(`lockUniScaling`)) {
      // middle-top
      this._drawControl(
        `mt`,
        ctx,
        methodName,
        left + width / 2,
        top,
        styleOverride
      );
      // middle-bottom
      this._drawControl(
        `mb`,
        ctx,
        methodName,
        left + width / 2,
        top + height,
        styleOverride
      );
      // middle-right
      this._drawControl(
        `mr`,
        ctx,
        methodName,
        left + width,
        top + height / 2,
        styleOverride
      );
      // middle-left
      this._drawControl(
        `ml`,
        ctx,
        methodName,
        left,
        top + height / 2,
        styleOverride
      );
    }
    // middle-top-rotate
    if (hasRotatingPoint) {
      this._drawControl(
        `mtr`,
        ctx,
        methodName,
        left + width / 2,
        top - this.rotatingPointOffset,
        styleOverride
      );

      const rotateLeft = left + width / 2;
      const rotateTop = top - this.rotatingPointOffset;
      if (!this.canvas.isMobile) {
        ctx.drawImage(rotateIcon, rotateLeft, rotateTop, 13, 13);
      } else {
        ctx.drawImage(rotateIcon, rotateLeft + 2, rotateTop + 3, 20, 20);
      }
    }
    ctx.restore();
    return this;
  };

  fabric.Object.prototype.renderHidden = function (ctx) {
    if (!this.__hidden) {
      return;
    }
    const {width, height} = this;
    ctx.save();
    ctx.fillStyle = `rgba(255,0,0,0.2)`;
    ctx.fillRect(-width / 2, -height / 2, width, height);
    ctx.restore();
  };

  // pattern to object doesn't have custom properties
  fabric.Object.prototype.toObject = function (propertiesToInclude) {
    let NUM_FRACTION_DIGITS = fabric.Object.NUM_FRACTION_DIGITS;
    let object = {
      id: this.id,
      type: this.type,
      version: fabric.version,
      originX: this.originX,
      originY: this.originY,
      left: fabric.util.toFixed(this.left, NUM_FRACTION_DIGITS),
      top: fabric.util.toFixed(this.top, NUM_FRACTION_DIGITS),
      width: fabric.util.toFixed(this.width, NUM_FRACTION_DIGITS),
      height: fabric.util.toFixed(this.height, NUM_FRACTION_DIGITS),
      fill:
        this.fill && this.fill.toObject
          ? this.fill.toObject(propertiesToInclude)
          : this.fill,
      stroke:
        this.stroke && this.stroke.toObject
          ? this.stroke.toObject()
          : this.stroke,
      strokeWidth: fabric.util.toFixed(this.strokeWidth, NUM_FRACTION_DIGITS),
      strokeDashArray: this.strokeDashArray
        ? this.strokeDashArray.concat()
        : this.strokeDashArray,
      strokeLineCap: this.strokeLineCap,
      strokeDashOffset: this.strokeDashOffset,
      strokeLineJoin: this.strokeLineJoin,
      strokeMiterLimit: fabric.util.toFixed(
        this.strokeMiterLimit,
        NUM_FRACTION_DIGITS
      ),
      scaleX: fabric.util.toFixed(this.scaleX, NUM_FRACTION_DIGITS),
      scaleY: fabric.util.toFixed(this.scaleY, NUM_FRACTION_DIGITS),
      angle: fabric.util.toFixed(this.angle, NUM_FRACTION_DIGITS),
      flipX: this.flipX,
      flipY: this.flipY,
      opacity: fabric.util.toFixed(this.opacity, NUM_FRACTION_DIGITS),
      shadow:
        this.shadow && this.shadow.toObject
          ? this.shadow.toObject()
          : this.shadow,
      visible: this.visible,
      clipTo: this.clipTo && String(this.clipTo),
      backgroundColor: this.backgroundColor,
      fillRule: this.fillRule,
      paintFirst: this.paintFirst,
      globalCompositeOperation: this.globalCompositeOperation,
      transformMatrix: this.transformMatrix
        ? this.transformMatrix.concat()
        : null,
      skewX: fabric.util.toFixed(this.skewX, NUM_FRACTION_DIGITS),
      skewY: fabric.util.toFixed(this.skewY, NUM_FRACTION_DIGITS)
    };

    if (this.clipPath) {
      object.clipPath = this.clipPath.toObject(propertiesToInclude);
      object.clipPath.inverted = this.clipPath.inverted;
      object.clipPath.absolutePositioned = this.clipPath.absolutePositioned;
    }

    fabric.util.populateWithProperties(this, object, propertiesToInclude);
    if (!this.includeDefaultValues) {
      object = this._removeDefaultValues(object);
    }

    return object;
  };

  fabric.Tiling = fabric.util.createClass(fabric.Rect, {
    type: `tiling`,
    tile: {
      type: `basic`,
      objects: [],
      width: 0,
      height: 0,
      originalObject: null
    },
    tilePadding: 0,
    tileScale: 1,
    objectCaching: false,
    multiplier: 1,
    initialize(options = {}) {
      this.callSuper(`initialize`, options);
      this.setOptions(options);
      this._setFill();
    },
    setOptions(options) {
      for (const prop in options) {
        if (options.hasOwnProperty(prop)) {
          this[prop] = options[prop];
        }
      }
    },
    _setFill() {
      this.fill = this._createPattern();
    },
    _createPattern() {
      const patternSourceCanvas = new fabric.StaticCanvas();
      patternSourceCanvas.enableRetinaScaling = false;
      patternSourceCanvas.setDimensions({
        width: this.multiplier * this.tile.width,
        height: this.multiplier * this.tile.height
      });

      patternSourceCanvas.add(...this.tile.objects);
      patternSourceCanvas.contextContainer.scale(
        this.multiplier,
        this.multiplier
      );
      patternSourceCanvas.renderAll();
      return new fabric.Pattern({
        source: patternSourceCanvas.getElement(),
        patternTransform: [
          this.tileScale / this.multiplier,
          0,
          0,
          this.tileScale / this.multiplier,
          0,
          0
        ]
      });
    },
    updateTiling() {
      this._setFill();
    },
    getOriginalObject() {
      return this.tile.originalObject;
    },
    toObject(propertiesToInclude) {
      return this.callSuper(
        `toObject`,
        [`tile`, `tilePadding`, `tileScale`].concat(propertiesToInclude)
      );
    }
  });

  fabric.Tiling.fromObject = function (object, callback) {
    if (object.tile.objects[0].isType) {
      callback(new fabric.Tiling(object));
    } else {
      fabric.util.enlivenObjects(object.tile.objects, function (
        enlivenObjects
      ) {
        object.tile.objects = enlivenObjects;
        fabric.util.enlivenObjects([object.tile.originalObject], function (
          enlivenObj
        ) {
          object.tile.originalObject = enlivenObj[0];
          callback(new fabric.Tiling(object));
        });
      });
    }
  };

  // gestures
  (function () {
    let degreesToRadians = fabric.util.degreesToRadians;
    let radiansToDegrees = fabric.util.radiansToDegrees;

    fabric.util.object.extend(
      fabric.Canvas.prototype,
      /** @lends fabric.Canvas.prototype */ {
        /**
         * Method that defines actions when an Event.js gesture is detected on an object. Currently only supports
         * 2 finger gestures.
         * @param {Event} e Event object by Event.js
         * @param {Event} self Event proxy object by Event.js
         */
        __onTransformGesture(e, self) {
          if (
            this.isDrawingMode ||
            !e.touches ||
            e.touches.length !== 2 ||
            `gesture` !== self.gesture
          ) {
            return;
          }

          let target = this.findTarget(e);
          if (`undefined` !== typeof target) {
            this.__gesturesParams = {
              e,
              self,
              target
            };

            this.__gesturesRenderer();
          }

          this.fire(`touch:gesture`, {
            target,
            e,
            self
          });
        },
        __gesturesParams: null,
        __gesturesRenderer() {
          if (
            this.__gesturesParams === null ||
            this._currentTransform === null
          ) {
            return;
          }

          let self = this.__gesturesParams.self;
          let t = this._currentTransform;
          let e = this.__gesturesParams.e;

          t.action = `scale`;
          t.originX = t.originY = `center`;

          this._scaleObjectBy(self.scale, e);

          if (self.rotation !== 0) {
            t.action = `rotate`;
            this._rotateObjectByAngle(self.rotation, e);
          }

          this.requestRenderAll();
          t.action = `drag`;
        },

        /**
         * Method that defines actions when an Event.js drag is detected.
         *
         * @param {Event} e Event object by Event.js
         * @param {Event} self Event proxy object by Event.js
         */
        __onDrag(e, self) {
          this.fire(`touch:drag`, {
            e,
            self
          });
        },

        /**
         * Method that defines actions when an Event.js orientation event is detected.
         *
         * @param {Event} e Event object by Event.js
         * @param {Event} self Event proxy object by Event.js
         */
        __onOrientationChange(e, self) {
          this.fire(`touch:orientation`, {
            e,
            self
          });
        },

        /**
         * Method that defines actions when an Event.js shake event is detected.
         *
         * @param {Event} e Event object by Event.js
         * @param {Event} self Event proxy object by Event.js
         */
        __onShake(e, self) {
          this.fire(`touch:shake`, {
            e,
            self
          });
        },

        /**
         * Method that defines actions when an Event.js longpress event is detected.
         *
         * @param {Event} e Event object by Event.js
         * @param {Event} self Event proxy object by Event.js
         */
        __onLongPress(e, self) {
          this.fire(`touch:longpress`, {
            e,
            self
          });
        },

        /**
         * Scales an object by a factor
         * @param {Number} s The scale factor to apply to the current scale level
         * @param {Event} e Event object by Event.js
         */
        _scaleObjectBy(s, e) {
          let t = this._currentTransform;
          let target = t.target;
          let lockScalingX = target.get(`lockScalingX`);
          let lockScalingY = target.get(`lockScalingY`);

          if (lockScalingX && lockScalingY) {
            return;
          }

          target._scaling = true;

          let constraintPosition = target.translateToOriginPoint(
            target.getCenterPoint(),
            t.originX,
            t.originY
          );
          let dim = target._getTransformedDimensions();

          this._setObjectScale(
            new fabric.Point(
              (t.scaleX * dim.x * s) / target.scaleX,
              (t.scaleY * dim.y * s) / target.scaleY
            ),
            t,
            lockScalingX,
            lockScalingY,
            null,
            target.get(`lockScalingFlip`),
            dim
          );

          target.setPositionByOrigin(constraintPosition, t.originX, t.originY);

          this._fire(`scaling`, {
            target,
            e,
            transform: t
          });
        },

        /**
         * Rotates object by an angle
         * @param {Number} curAngle The angle of rotation in degrees
         * @param {Event} e Event object by Event.js
         */
        _rotateObjectByAngle(curAngle, e) {
          let t = this._currentTransform;

          if (t.target.get(`lockRotation`)) {
            return;
          }
          t.target.rotate(
            radiansToDegrees(degreesToRadians(curAngle) + t.theta)
          );
          this._fire(`rotating`, {
            target: t.target,
            e,
            transform: t
          });
        }
      }
    );
  })();

  // snap angle
  fabric.util.object.extend(fabric.Canvas.prototype, {
    _rotateObjectWithOutSnappig: fabric.Canvas.prototype._rotateObject,
    _rotateObject(x, y) {
      this._rotateObjectWithOutSnappig(x, y);
      let t = this._currentTransform;

      if (typeof t.target.snapAngle !== `number`) {
        return void 0;
      }

      let angle = t.target.angle;
      let snapAngle = t.target.snapAngle;
      let snapThreshold = t.target.snapThreshold || snapAngle;
      let rightAngleLocked = Math.ceil(angle / snapAngle) * snapAngle;
      let leftAngleLocked = Math.floor(angle / snapAngle) * snapAngle;

      if (Math.abs(angle - leftAngleLocked) < snapThreshold) {
        t.target.angle = leftAngleLocked;
      } else if (Math.abs(angle - rightAngleLocked) < snapThreshold) {
        t.target.angle = rightAngleLocked;
      }

      return this;
    }
  });
};

export default extendFabric;
