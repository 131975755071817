import React from 'react'
import cn from 'classnames'

import styles from './loader.module.scss'

export default function Loader(props) {
    return (
        <div
            className={cn(styles.ldsRolling, {
                [styles.ldsRollingSmall]: props.mod,
            })}
            data-testid="loader"
        >
            <div />
        </div>
    )
}
