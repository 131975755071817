export const CANVAS = {
  ID: `c`,
  WIDTH: 600,
  HEIGHT: 600
};

export const REALISM_POLL_TIME = 2000;
export const PICTURE_POLL_TIME = 1000;

export const DEFAULT_DESIGN = `{"objects":[],"background":"transparent"}`;
