import ObjectResizer from "./lib/object-resizer";
import extendFabric from "./lib/fabric-extensions";
import extendFabricFilters from "./lib/filters";
import drawGuideLines from "./lib/drown-guidlines";

class Canvas {
  init({id, ...options} = {id: `c`, options: {}}) {
    this._initDefaultValues(options);
    this.canvas = new window.fabric.Canvas(id, {
      ...options,
      hoverCursor: `pointer`,
      controlsAboveOverlay: true,
      allowTouchScrolling: true,
      fireRightClick: true,
      preserveObjectStacking: true,
      selection: !options.isMobile
    });
    this.dimensions = [0, 0, 600, 600];
    this.prevDimensions = [];
    return this.canvas;
  }
  addObject(object, {offsetX, offsetY} = {offsetX: 0, offsetY: 0}) {
    if (object.isType(`activeSelection`)) {
      const objects = [];
      object.getObjects().forEach((obj) => {
        const {group = {left: 0, top: 0}} = obj;
        const {left: ownLeft, top: ownTop} = obj;
        delete obj.group;
        obj.set({
          top: group.top + ownTop + offsetX,
          left: group.left + ownLeft + offsetY
        });
        objects.push(obj);
        this.canvas.add(obj);
      });
      return objects;
    } else {
      const {top, left} = object;
      object.set({
        top: top + offsetY,
        left: left + offsetX
      });
      this.canvas.add(object);
      return object;
    }
  }
  removeObject(object) {
    if (object.isType(`activeSelection`)) {
      const objects = [];
      object.getObjects().forEach((obj) => {
        this.canvas.remove(obj);
        objects.push(obj);
      });
      return objects;
    } else {
      this.canvas.remove(object);
      return object;
    }
  }
  cloneObject(object) {
    return new Promise((resolve, reject) => {
      try {
        object.clone((cloneObj) => {
          if (object.clipPath) {
            cloneObj.clipPath.id = object.clipPath.id;
          }
          resolve(cloneObj);
        });
      } catch (e) {
        reject(e);
      }
    });
  }
  getObjects() {
    return this.canvas.getObjects();
  }
  unSelectObjects() {
    this.discardActiveObject();
  }
  selectObjects(selection) {
    if (Array.isArray(selection)) {
      if (selection.length > 1) {
        const activeSelection = new window.fabric.ActiveSelection(selection, {
          canvas: this.canvas
        });
        activeSelection.canvas = this.canvas;
        this.discardActiveObject();
        this.setActiveObject(activeSelection);
        this.canvas.renderAll();
        return activeSelection;
      } else if (selection.length === 1) {
        this.discardActiveObject();
        this.setActiveObject(selection[0]);
        this.canvas.renderAll();
        return selection[0];
      } else if (selection.length && selection.isType(`activeSelection`)) {
        const temp = [];
        for (let objects = selection.getObjects(), i = objects.length; i--; ) {
          temp[i] = objects[i];
        }
        selection._objects.length = 0;
        for (let j = temp.length; j--; ) {
          selection.addWithUpdate(temp[j]);
        }
        selection.saveState();
        selection.getObjects().forEach((obj) => {
          obj.saveState();
        });
        this.discardActiveObject();
        this.setActiveObject(selection);
        this.canvas.renderAll();
        return selection;
      } else {
        return void 0;
      }
    } else {
      this.discardActiveObject();
      this.setActiveObject(selection);
      this.canvas.renderAll();
      return selection;
    }
  }
  selectAllObjects() {
    this.discardActiveObject();
    this.selectObjects(this.canvas.getObjects());
  }
  discardActiveObject() {
    this.canvas._discardActiveObject(); // for silent
  }
  setActiveObject(object) {
    this.canvas._setActiveObject(object); // for silent
  }
  setBackgroundImage(url) {
    return new Promise((resolve, reject) => {
      try {
        this.canvas.setBackgroundImage(
          url,
          () => {
            this.canvas.renderAll();
            resolve();
          },
          {
            originX: `left`,
            originY: `top`
          }
        );
      } catch (e) {
        reject(e);
      }
    });
  }
  setOverlayImage(url) {
    if (url) {
      return new Promise((resolve, reject) => {
        try {
          this.canvas.setOverlayImage(
            url,
            () => {
              resolve();
            },
            {
              originX: `left`,
              originY: `top`
            }
          );
        } catch (e) {
          reject(e);
        }
      });
    }
    this.canvas.set({
      overlayImage: null
    });
    return void 0;
  }
  setBackgroundColor(color) {
    this.canvas.setBackgroundColor(color, this.renderAll.bind(this));
  }
  applyDesign(json, canvas) {
    return new Promise((resolve, reject) => {
      try {
        canvas.loadFromJSON(json, () => {
          canvas.forEachObject((obj) => {
            if (obj.type === `image` && obj.filters.length) {
              obj.applyFilters();
              canvas.renderAll();
            }
            // fallback for old editor
            if (obj.fontFamily === `Times New Roman`) {
              obj.fontFamily = `Helvetica`;
            }
            obj.saveState();
          });
          resolve();
        });
      } catch (e) {
        reject(e);
      }
    });
  }
  applyClipping(guideLineOffset) {
    const [top, left, width, height] = this.dimensions;
    const right = left + width;
    const bottom = top + height;

    const withGuidLines = guideLineOffset === 0;

    const clipToWithGuideLines = (ctx) => {
      ctx.strokeStyle = `rgb(190,190,190)`;
      ctx.beginPath();
      ctx.setLineDash([5, 5]);
      ctx.moveTo(left, top);
      ctx.lineTo(right, top);
      ctx.lineTo(right, bottom);
      ctx.lineTo(left, bottom);
      ctx.lineTo(left, top);
      ctx.stroke();
    };

    const clipToWithOutGuideLines = (ctx) => {
      ctx.beginPath();
      ctx.moveTo(left, top);
      ctx.lineTo(right, top);
      ctx.lineTo(right, bottom);
      ctx.lineTo(left, bottom);
      ctx.lineTo(left, top);
      ctx.closePath();
    };

    const drawIndentedGuideLines = () =>
      drawGuideLines(this.canvas, this.dimensions, guideLineOffset);

    if (
      !withGuidLines &&
      JSON.stringify(this.dimensions) !== JSON.stringify(this.prevDimensions)
    ) {
      this.canvas.on(`before:render`, drawIndentedGuideLines);
      this.prevDimensions = [...this.dimensions];
    }

    this.canvas.clipTo = withGuidLines
      ? clipToWithGuideLines
      : clipToWithOutGuideLines;
    this.canvas.renderAll();
  }
  toJSON() {
    const data = this.canvas.toJSON([
      `id`,
      `imageType`,
      `originalSrc`,
      `originalHeight`,
      `originalWidth`,
      `selectable`,
      `hasControls`,
      `hasBorders`,
      `static`,
      `evented`,
      `fields`
    ]);
    delete data.overlayImage;
    delete data.width;
    delete data.height;
    return data;
  }
  add(object) {
    this.canvas.add(object);
  }
  renderAll() {
    this.canvas.renderAll();
  }
  getActive() {
    return this.canvas.getActiveObject();
  }
  removeAllExceptObject(object, cb) {
    const stringifyActiveObject = JSON.stringify(object);
    this.canvas.getObjects().forEach((obj) => {
      if (stringifyActiveObject !== JSON.stringify(obj)) {
        this.canvas.remove(obj);
        if (cb) {
          cb(obj);
        }
      }
    });
  }
  flipHorizontally(object) {
    object.saveState().toggle(`flipX`);
    this.renderAll();
  }
  flipVertically(object) {
    object.saveState().toggle(`flipY`);
    this.renderAll();
  }
  straightenObject(object) {
    this.canvas.straightenObject(object);
  }
  sendObjectToBack(object) {
    this.canvas.sendToBack(object);
    this.renderAll();
  }
  sendObjectToBackwards(object) {
    this.canvas.sendBackwards(object);
    this.renderAll();
  }
  bringObjectToFront(object) {
    this.canvas.bringToFront(object);
    this.renderAll();
  }
  bringObjectToForward(object) {
    this.canvas.bringForward(object);
    this.renderAll();
  }
  centerObjectVertically(object) {
    const [top, , , height] = this.dimensions;
    object
      .set({
        top:
          object.originY === `center`
            ? height / 2 + top
            : height / 2 + top - object.height / 2
      })
      .setCoords();
    this.renderAll();
  }
  centerObjectHorizontally(object) {
    const [, left, width] = this.dimensions;
    object
      .set({
        left:
          object.originX === `center`
            ? width / 2 + left
            : width / 2 + left - object.width / 2
      })
      .setCoords();
    this.renderAll();
  }
  resizeObjectToFill(object) {
    ObjectResizer.resizeToFill(object);
    this.centerObjectVertically(object);
    this.centerObjectHorizontally(object);
    this.renderAll();
  }
  restoreRatioOfObject(object) {
    const {scaleX, scaleY} = object;
    const scale = scaleX > scaleY ? scaleY : scaleX;

    object.scale(scale);
    this.renderAll();
  }
  loadImageFromURL(url, options) {
    return new Promise((resolve, reject) => {
      try {
        window.fabric.Image.fromURL(url, (img) => resolve(img.set(options)), {
          crossOrigin: `anonymous`
        });
      } catch (e) {
        reject(e);
      }
    });
  }
  loadSVGFromURL(url, options = {}) {
    return new Promise((resolve, reject) => {
      try {
        window.fabric.loadSVGFromURL(url, (elements) => {
          resolve(window.fabric.util.groupSVGElements(elements).set(options));
        });
      } catch (e) {
        reject(e);
      }
    });
  }
  createText(text, options) {
    return new Promise((resolve, reject) => {
      try {
        resolve(new window.fabric.IText(text, options));
      } catch (e) {
        reject(e);
      }
    });
  }
  clear() {
    // method canvas.clear() removes overlayImage
    const canvas = this.canvas;
    canvas._objects.length = 0;
    canvas.backgroundColor = ``;
    canvas.overlayColor = ``;
    if (canvas._hasITextHandlers) {
      canvas.off(`mouse:up`, canvas._mouseUpITextHandler);
      canvas._iTextInstances = null;
      canvas._hasITextHandlers = false;
    }
    canvas.clearContext(canvas.contextContainer);
    canvas.fire(`canvas:cleared`);
    if (canvas.renderOnAddRemove) {
      canvas.requestRenderAll();
    }
    this.renderAll();
    return canvas;
  }
  setDimensions(dimensions) {
    this.dimensions = dimensions;
  }
  saveState() {
    this.canvas._state = {
      backgroundColor: this.canvas.backgroundColor || `transparent`
    };
  }
  moveObjectTo(object, direction, delta) {
    switch (direction) {
      case `left`:
        return object.set(`left`, object.get(`left`) - delta).setCoords();
      case `right`:
        return object.set(`left`, object.get(`left`) + delta).setCoords();
      case `top`:
        return object.set(`top`, object.get(`top`) - delta).setCoords();
      case `down`:
        return object.set(`top`, object.get(`top`) + delta).setCoords();
      default:
        return void 0;
    }
  }
  addClipPathToObject(object, clipPath) {
    object.clipPath = clipPath;
  }
  addFilterToObject(object, {id, filter}) {
    object.filters[id] = filter;
    object.applyFilters();
  }
  isFilter(object) {
    return object && object.applyTo2d;
  }
  setOpacityToObject(object, value) {
    object.set(`opacity`, value);
  }
  setStrokeToObject(object, {stroke, strokeWidth}) {
    object.set({
      stroke,
      strokeWidth,
      strokeDashArray: [0, 0]
    });
  }
  setFillForPath(object, value) {
    object.set(`fill`, value);
    if (object.getObjects) {
      object.getObjects().forEach((obj) => obj.set(`fill`, value));
    }
  }
  markHiddenObjects() {
    this.canvas.getObjects().forEach((obj) => {
      // do not mark free drawn paths
      if (obj.freeDrawn) {
        return;
      }

      obj.__hidden = this.isObjectHidden(obj);
    });
  }
  renderHiddenObjects() {
    this.canvas.clearContext(this.canvas.contextTop);
    this.canvas.getObjects().forEach((obj) => {
      const ctx = this.canvas.contextTop;

      ctx.save();
      if (obj && window) {
        window.fabric.Object.prototype.transform.call(obj, ctx);
        obj.renderHidden(ctx);
      }
      ctx.restore();
    }, this);
  }

  isObjectHidden(obj) {
    const {
      width: boundingRectWidth,
      height: boundingRectHeight
    } = obj.getBoundingRect();
    let {left, top} = obj;
    if (obj.group) {
      left += obj.group.left;
      top += obj.group.top;
    }
    const [
      canvasDimensionsTop,
      canvasDimensionsLeft,
      canvasDimensionsWidth,
      canvasDimensionsHeight
    ] = this.dimensions;
    const isBehindRightEdge =
      left - boundingRectWidth / 2 >
      canvasDimensionsLeft + canvasDimensionsWidth;
    const isBehindLeftEdge =
      left + boundingRectWidth / 2 < canvasDimensionsLeft;
    const isBehindTopEdge = top + boundingRectHeight / 2 < canvasDimensionsTop;
    const isBehindBottomEdge =
      top - boundingRectHeight / 2 >
      canvasDimensionsTop + canvasDimensionsHeight;

    return (
      isBehindLeftEdge ||
      isBehindRightEdge ||
      isBehindTopEdge ||
      isBehindBottomEdge
    );
  }
  destroyTiling(tiling) {
    const originalObject = tiling.getOriginalObject();
    this.removeObject(tiling);
    this.addObject(originalObject);
    this.discardActiveObject();
    this.setActiveObject(originalObject);
    return originalObject;
  }
  async createTilingFromObject(object, type) {
    let originalObject = object;
    if (object.isType(`tiling`)) {
      if (object.tile.type === type) {
        return object;
      }
      originalObject = object.tile.originalObject;
    }
    const tile = await this._createObjectsForTiling(originalObject, type);
    const tiling = await this.createTiling(tile);

    originalObject.tiling = tiling;

    if (object.isType(`tiling`)) {
      this.setTilePadding(tiling, object.tilePadding);
    }

    this.removeObject(object);
    this.addObject(tiling);
    this.discardActiveObject();
    this.setActiveObject(tiling);
    return tiling;
  }
  async createTiling(tile) {
    return new window.fabric.Tiling({
      width: 550,
      height: 550,
      padding: 0,
      left: 300,
      top: 300,
      tile
    });
  }
  setTilePadding(object, value) {
    const {type, objects} = object.tile;
    if (type === `basic`) {
      const {
        width: scaledWidth,
        height: scaledHeight
      } = objects[0].getBoundingRect();

      objects[0].left = scaledWidth / 2 + value;
      objects[0].top = scaledHeight / 2 + value;

      object.tile.width = scaledWidth + value;
      object.tile.height = scaledHeight + value;

      object.tilePadding = value;

      object.updateTiling();
    } else if (type === `half-brick`) {
      const {
        width: scaledWidth,
        height: scaledHeight
      } = objects[0].getBoundingRect();
      objects[0].set({
        left: scaledWidth / 2 + value / 2,
        top: scaledHeight / 2 + value / 2
      });
      objects[1].set({
        left: (3 / 2) * scaledWidth + (3 / 2) * value,
        top: scaledHeight / 2 + value / 2
      });
      objects[2].set({
        top: (3 / 2) * scaledHeight + (3 / 2) * value
      });
      objects[3].set({
        left: scaledWidth + value,
        top: (3 / 2) * scaledHeight + (3 / 2) * value
      });
      objects[4].set({
        left: scaledWidth * 2 + 2 * value,
        top: (3 / 2) * scaledHeight + (3 / 2) * value
      });

      object.tile.width = 2 * (scaledWidth + value);
      object.tile.height = 2 * (scaledHeight + value);

      object.tilePadding = value;

      object.updateTiling();
    } else if (type === `centered`) {
      const {
        width: scaledWidth,
        height: scaledHeight
      } = objects[0].getBoundingRect();
      objects[0].set({
        left: 0,
        top: 0
      });
      objects[1].set({
        left: 2 * scaledWidth + 2 * value,
        top: 0
      });
      objects[2].set({
        left: scaledWidth + value,
        top: scaledHeight + value
      });
      objects[3].set({
        left: 0,
        top: 2 * scaledHeight + 2 * value
      });
      objects[4].set({
        left: 2 * scaledWidth + 2 * value,
        top: 2 * scaledHeight + 2 * value
      });

      object.tile.width = 2 * (scaledWidth + value);
      object.tile.height = 2 * (scaledHeight + value);

      object.tilePadding = value;

      object.updateTiling();
    } else if (type === `half-drop`) {
      const {
        width: scaledWidth,
        height: scaledHeight
      } = objects[0].getBoundingRect();
      objects[0].set({
        left: scaledWidth / 2 + value / 2,
        top: scaledHeight / 2 + value / 2
      });
      objects[1].set({
        left: (3 / 2) * scaledWidth + (3 / 2) * value,
        top: 0
      });
      objects[2].set({
        left: scaledWidth / 2 + value / 2,
        top: (3 / 2) * scaledHeight + (3 / 2) * value
      });
      objects[3].set({
        left: (3 / 2) * scaledWidth + (3 / 2) * value,
        top: scaledHeight + value
      });
      objects[4].set({
        left: (3 / 2) * scaledWidth + (3 / 2) * value,
        top: 2 * scaledHeight + 2 * value
      });

      object.tile.width = 2 * (scaledWidth + value);
      object.tile.height = 2 * (scaledHeight + value);

      object.tilePadding = value;

      object.updateTiling();
    } else if (type === `mirror`) {
      const {
        width: scaledWidth,
        height: scaledHeight
      } = objects[0].getBoundingRect();
      objects[0].set({
        left: scaledWidth / 2 + value / 2,
        top: scaledHeight / 2 + value / 2
      });
      objects[1].set({
        left: (3 / 2) * scaledWidth + (3 / 2) * value,
        top: scaledHeight / 2 + value / 2
      });
      objects[2].set({
        left: scaledWidth / 2 + value / 2,
        top: (3 / 2) * scaledHeight + (3 / 2) * value,
        angle: objects[0].angle + 180
      });
      objects[3].set({
        left: (3 / 2) * scaledWidth + (3 / 2) * value,
        top: (3 / 2) * scaledHeight + (3 / 2) * value,
        angle: objects[0].angle + 180
      });

      object.tile.width = 2 * (scaledWidth + value);
      object.tile.height = 2 * (scaledHeight + value);

      object.tilePadding = value;

      object.updateTiling();
    }
    this.renderAll();
  }
  enlivenObjects(objects) {
    return new Promise((resolve, reject) => {
      try {
        window.fabric.util.enlivenObjects(objects, (enlivenedObjects) => {
          resolve(enlivenedObjects);
        });
      } catch (error) {
        reject(error);
      }
    });
  }
  // private
  _initDefaultValues({isMobile}) {
    extendFabric();
    extendFabricFilters();

    window.fabric.Text.prototype.originY = `top`;
    window.fabric.Text.prototype.centeredRotation = true;
    window.fabric.Text.prototype.lockUniScaling = true;
    window.fabric.Object.prototype.originX = `center`;
    window.fabric.Object.prototype.originY = `center`;
    window.fabric.Object.prototype.rotatingPointOffset = 0;

    window.fabric.Object.prototype.snapAngle = 45;
    window.fabric.Object.prototype.snapThreshold = 10;

    window.fabric.Object.prototype._controlsVisibility = {
      tl: true,
      tr: true,
      br: true,
      bl: true,
      ml: false,
      mt: false,
      mr: false,
      mb: false,
      mtr: true
    };

    // remove clipPath from saveState
    const stateProperties = [...window.fabric.Object.prototype.stateProperties];
    const indexOfClipPath = stateProperties.indexOf(`clipPath`);
    stateProperties.splice(indexOfClipPath, 1);
    window.fabric.Object.prototype.stateProperties = stateProperties;
    window.fabric.Image.prototype.stateProperties = stateProperties;

    // controls
    window.fabric.Object.prototype.transparentCorners = false;
    window.fabric.Object.prototype.cornerStyle = `circle`;
    window.fabric.Object.prototype.borderColor = `#04a3ff`;
    window.fabric.Object.prototype.cornerColor = `#04a3ff`;
    window.fabric.Object.prototype.cornerStrokeColor = `#04a3ff`;
    if (isMobile) {
      window.fabric.Object.prototype.cornerSize = 25;
      window.fabric.IText.prototype.editable = false;
    }
  }
  _addDimensionsToData(data) {
    const [top, left] = this.dimensions;
    const cloneData = {...data};
    delete cloneData.width;
    delete cloneData.height;
    if (cloneData.objects) {
      return {
        ...cloneData,
        objects: cloneData.objects.map((obj) => {
          return {
            ...obj,
            left: obj.left + left,
            top: obj.top + top
          };
        })
      };
    }
    return cloneData;
  }
  _getBoundingRect(object) {
    const angleOfRadian = (Math.PI / 180) * (object.angle % 180);
    const width =
      Math.cos(angleOfRadian) * object.getScaledWidth() +
      Math.sin(angleOfRadian) * object.getScaledHeight();
    const height =
      Math.sin(angleOfRadian) * object.getScaledWidth() +
      Math.cos(angleOfRadian) * object.getScaledHeight();

    return {
      width,
      height
    };
  }
  async _createObjectsForTiling(object, type) {
    if (type === `basic`) {
      const cloneObject = await this.cloneObject(object);
      cloneObject.set({
        originX: `center`,
        originY: `center`,
        objectCaching: false
      });
      cloneObject.scaleToWidth(Math.round(cloneObject.getBoundingRect().width));

      const {
        width: scaledWidth,
        height: scaledHeight
      } = cloneObject.getBoundingRect();

      cloneObject.set({
        left: scaledWidth / 2,
        top: scaledHeight / 2
      });
      return {
        type: `basic`,
        objects: [cloneObject],
        originalObject: object,
        tilePadding: 0,
        width: scaledWidth,
        height: scaledHeight
      };
    } else if (type === `half-brick`) {
      const arrayOfObjects = [
        await this.cloneObject(object),
        await this.cloneObject(object),
        await this.cloneObject(object),
        await this.cloneObject(object),
        await this.cloneObject(object)
      ];
      arrayOfObjects.forEach((obj) => {
        obj.set({
          originX: `center`,
          originY: `center`,
          objectCaching: false
        });
        obj.scaleToWidth(Math.round(obj.getBoundingRect().width));
      });
      const {
        width: scaledWidth,
        height: scaledHeight
      } = arrayOfObjects[0].getBoundingRect();
      arrayOfObjects[0].set({
        left: scaledWidth / 2,
        top: scaledHeight / 2
      });
      arrayOfObjects[1].set({
        left: (3 / 2) * scaledWidth,
        top: scaledHeight / 2
      });
      arrayOfObjects[2].set({
        left: 0,
        top: (3 / 2) * scaledHeight
      });
      arrayOfObjects[3].set({
        left: scaledWidth,
        top: (3 / 2) * scaledHeight
      });
      arrayOfObjects[4].set({
        left: scaledWidth * 2,
        top: (3 / 2) * scaledHeight
      });

      return {
        type: `half-brick`,
        objects: arrayOfObjects,
        originalObject: object,
        tilePadding: 0,
        width: scaledWidth * 2,
        height: scaledHeight * 2
      };
    } else if (type === `centered`) {
      const arrayOfObjects = [
        await this.cloneObject(object),
        await this.cloneObject(object),
        await this.cloneObject(object),
        await this.cloneObject(object),
        await this.cloneObject(object)
      ];
      arrayOfObjects.forEach((obj) => {
        obj.set({
          originX: `center`,
          originY: `center`,
          objectCaching: false
        });
        obj.scaleToWidth(Math.round(obj.getBoundingRect().width));
      });
      const {
        width: scaledWidth,
        height: scaledHeight
      } = arrayOfObjects[0].getBoundingRect();

      arrayOfObjects[0].set({
        left: 0,
        top: 0
      });
      arrayOfObjects[1].set({
        left: 2 * scaledWidth,
        top: 0
      });
      arrayOfObjects[2].set({
        left: scaledWidth,
        top: scaledHeight
      });
      arrayOfObjects[3].set({
        left: 0,
        top: 2 * scaledHeight
      });
      arrayOfObjects[4].set({
        left: 2 * scaledWidth,
        top: 2 * scaledHeight
      });
      return {
        type: `centered`,
        objects: arrayOfObjects,
        originalObject: object,
        tilePadding: 0,
        width: scaledWidth * 2,
        height: scaledHeight * 2
      };
    } else if (type === `half-drop`) {
      const arrayOfObjects = [
        await this.cloneObject(object),
        await this.cloneObject(object),
        await this.cloneObject(object),
        await this.cloneObject(object),
        await this.cloneObject(object)
      ];
      arrayOfObjects.forEach((obj) => {
        obj.set({
          originX: `center`,
          originY: `center`,
          objectCaching: false
        });
        obj.scaleToWidth(Math.round(obj.getBoundingRect().width));
      });
      const {
        width: scaledWidth,
        height: scaledHeight
      } = arrayOfObjects[0].getBoundingRect();

      arrayOfObjects[0].set({
        left: scaledWidth / 2,
        top: scaledHeight / 2
      });
      arrayOfObjects[1].set({
        left: (3 / 2) * scaledWidth,
        top: 0
      });
      arrayOfObjects[2].set({
        left: scaledWidth / 2,
        top: (3 / 2) * scaledHeight
      });
      arrayOfObjects[3].set({
        left: (3 / 2) * scaledWidth,
        top: scaledHeight
      });
      arrayOfObjects[4].set({
        left: (3 / 2) * scaledWidth,
        top: 2 * scaledHeight
      });
      return {
        type: `half-drop`,
        objects: arrayOfObjects,
        originalObject: object,
        tilePadding: 0,
        width: scaledWidth * 2,
        height: scaledHeight * 2
      };
    } else if (type === `mirror`) {
      const arrayOfObjects = [
        await this.cloneObject(object),
        await this.cloneObject(object),
        await this.cloneObject(object),
        await this.cloneObject(object)
      ];
      arrayOfObjects.forEach((obj) => {
        obj.set({
          originX: `center`,
          originY: `center`,
          objectCaching: false
        });
        obj.scaleToWidth(Math.round(obj.getBoundingRect().width));
      });
      const {
        width: scaledWidth,
        height: scaledHeight
      } = arrayOfObjects[0].getBoundingRect();

      arrayOfObjects[0].set({
        left: scaledWidth / 2,
        top: scaledHeight / 2
      });
      arrayOfObjects[1].set({
        left: (3 / 2) * scaledWidth,
        top: scaledHeight / 2
      });
      arrayOfObjects[2].set({
        left: scaledWidth / 2,
        top: (3 / 2) * scaledHeight,
        angle: arrayOfObjects[0].angle + 180
      });
      arrayOfObjects[3].set({
        left: (3 / 2) * scaledWidth,
        top: (3 / 2) * scaledHeight,
        angle: arrayOfObjects[0].angle + 180
      });
      return {
        type: `mirror`,
        objects: arrayOfObjects,
        originalObject: object,
        tilePadding: 0,
        width: scaledWidth * 2,
        height: scaledHeight * 2
      };
    }
    return void 0;
  }
}

export default new Canvas();
