class SetBgCommand {
  constructor(receiver, controller) {
    this.receiver = receiver;
    this.controller = controller;

    this.currentColor = receiver.backgroundColor;
    this.prevColor = receiver._state.backgroundColor;
  }
  execute() {
    this.controller.setBackgroundColor(this.currentColor, true /* silent */);
  }
  undo() {
    this.controller.setBackgroundColor(this.prevColor, true /* silent */);
  }
}

export default SetBgCommand;
