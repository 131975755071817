export default {
  variants: [
    {
      name: `mug_color`,
      keyInProductMeta: `mug_colors`,
      filterOfItemsBy: /(white)|(-handle)/,
      title: `Цвет изделия`,
      subTitle: `Цветная ручка и ободок`,
      template: `Line`,
      description: false
    },
    {
      name: `mug_color`,
      keyInProductMeta: `mug_colors`,
      filterOfItemsBy: /(white)|(-inside)/,
      title: `Цветная внутри`,
      template: `Line`,
      description: false
    }
  ]
};
