import SceneManager from "./SceneManager";

// The singlton ThreeJSMediator is responsible for:

// Creating the canvas
// Binding events
// Creating Three.js objects
// Starting the render loop
// Managing all entities

class ThreeJSMediator {
  enable(containerElement) {
    this.canvas = this.createCanvas(document, containerElement);
    this.sceneManager = new SceneManager(this.canvas);
    this.bindEventListeners();
    this.render();
  }
  disable() {
    cancelAnimationFrame(this.rafId);
    this.unBindEventListeners();
  }
  async applySettings(settings) {
    await this.sceneManager.applySettings(settings);
  }
  createCanvas(document, containerElement) {
    const canvas = document.createElement(`canvas`);
    containerElement.appendChild(canvas);
    return canvas;
  }
  unBindEventListeners() {
    window.removeEventListener(`onresize`, this.resizeCanvas.bind(this));
  }
  bindEventListeners() {
    window.onresize = this.resizeCanvas.bind(this);
    this.resizeCanvas();
  }
  resizeCanvas() {
    this.canvas.style.width = `100%`;
    this.canvas.style.height = `100%`;
    this.canvas.width = this.canvas.offsetWidth;
    this.canvas.height = this.canvas.offsetHeight;
    this.sceneManager.onWindowResize();
  }
  render() {
    this.rafId = requestAnimationFrame(this.render.bind(this));
    this.sceneManager.update();
  }
}

export default new ThreeJSMediator();
