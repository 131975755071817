class CreateTilingCommand {
  constructor(receiver, controller) {
    this.receiver = receiver;
    this.controller = controller;
  }
  execute() {
    this.controller.createTilingFromObject(
      this.receiver,
      this.receiver.tiling.tile.type
    );
  }
  undo() {
    this.controller.destroyTiling(this.receiver.tiling);
  }
}

export default CreateTilingCommand;
