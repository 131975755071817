import Provider from "pubsub-js";
import {HISTORY_CHANGED, HISTORY_ADD} from "../../../constants/event-types";

class CommandHistory {
  commands = [];
  index = 0;
  getIndex() {
    return this.index;
  }
  back() {
    if (this.index > 0) {
      let command = this.commands[--this.index];
      if (command.async) {
        command.onComplete = function () {
          Provider.publish(HISTORY_CHANGED);
        };
        command.undo();
      } else {
        command.undo();
        Provider.publish(HISTORY_CHANGED);
      }
    }
    return this;
  }
  forward() {
    if (this.index < this.commands.length) {
      let command = this.commands[this.index++];
      if (command.async) {
        command.onComplete = function () {
          Provider.publish(HISTORY_CHANGED);
        };
        command.execute();
      } else {
        command.execute();
        Provider.publish(HISTORY_CHANGED);
      }
    }
    return this;
  }
  add(command, silent) {
    // Remove commands items >= current index
    // Opera throws Error when trying to splice empty array,
    // so we guard against it by checking length;
    if (this.commands.length) {
      this.commands.splice(this.index, this.commands.length - this.index);
    }
    this.commands.push(command);
    this.index++;
    if (!silent) {
      Provider.publish(HISTORY_ADD);
    }
    return this;
  }
  clear() {
    this.commands.length = 0;
    this.index = 0;
    Provider.publish(HISTORY_CHANGED);
    return this;
  }
}

export default CommandHistory;
