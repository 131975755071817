function initCenteringGuidelines(canvas) {
  const fabric = window && window.fabric;
  const canvasWidth = canvas.getWidth();
  const canvasHeight = canvas.getHeight();
  const canvasWidthCenter = canvasWidth / 2;
  const canvasHeightCenter = canvasHeight / 2;
  const canvasWidthCenterMap = {};
  const canvasHeightCenterMap = {};
  const centerLineMargin = 4;
  const centerLineColor = `rgb(33, 150, 243)`;
  const centerLineWidth = 1;
  const ctx = canvas.contextContainer;
  let viewportTransform;

  for (
    let i = canvasWidthCenter - centerLineMargin,
      len = canvasWidthCenter + centerLineMargin;
    i <= len;
    i++
  ) {
    canvasWidthCenterMap[Math.round(i)] = true;
  }
  for (
    let i = canvasHeightCenter - centerLineMargin,
      len = canvasHeightCenter + centerLineMargin;
    i <= len;
    i++
  ) {
    canvasHeightCenterMap[Math.round(i)] = true;
  }

  function showVerticalCenterLine() {
    showCenterLine(
      canvasWidthCenter + 0.5,
      0,
      canvasWidthCenter + 0.5,
      canvasHeight
    );
  }

  function showHorizontalCenterLine() {
    showCenterLine(
      0,
      canvasHeightCenter + 0.5,
      canvasWidth,
      canvasHeightCenter + 0.5
    );
  }

  function showCenterLine(x1, y1, x2, y2) {
    ctx.save();
    ctx.strokeStyle = centerLineColor;
    ctx.lineWidth = centerLineWidth;
    ctx.beginPath();
    ctx.moveTo(x1 * viewportTransform[0], y1 * viewportTransform[3]);
    ctx.lineTo(x2 * viewportTransform[0], y2 * viewportTransform[3]);
    ctx.stroke();
    ctx.restore();
  }

  let isInVerticalCenter;
  let isInHorizontalCenter;

  canvas.on(`mouse:down`, function () {
    viewportTransform = canvas.viewportTransform;
  });

  canvas.on(`object:moving`, function (e) {
    const object = e.target;
    const objectCenter = object.getCenterPoint();
    let transform = canvas._currentTransform;

    if (!transform) {
      return;
    }

    isInVerticalCenter = Math.round(objectCenter.x) in canvasWidthCenterMap;
    isInHorizontalCenter = Math.round(objectCenter.y) in canvasHeightCenterMap;

    if (isInHorizontalCenter || isInVerticalCenter) {
      object.setPositionByOrigin(
        new fabric.Point(
          isInVerticalCenter ? canvasWidthCenter : objectCenter.x,
          isInHorizontalCenter ? canvasHeightCenter : objectCenter.y
        ),
        `center`,
        `center`
      );
    }
  });

  canvas.on(`before:render`, function () {
    canvas.clearContext(canvas.contextContainer);
  });

  canvas.on(`after:render`, function () {
    if (isInVerticalCenter) {
      showVerticalCenterLine();
    }
    if (isInHorizontalCenter) {
      showHorizontalCenterLine();
    }
  });

  canvas.on(`mouse:up`, function () {
    // clear these values, to stop drawing guidelines once mouse is up
    isInVerticalCenter = isInHorizontalCenter = null;
    canvas.renderAll();
  });
}

export default initCenteringGuidelines;
