const months = [
  `january`,
  `february`,
  `march`,
  `april`,
  `may`,
  `june`,
  `july`,
  `august`,
  `september`,
  `october`,
  `november`,
  `december`
];

const objectToLoad = months.reduce((acc, month) => {
  return {
    ...acc,
    [month]: {
      path: `/shapes/svg/${month}.svg`,
      left: 305,
      top: 450,
      scaleX: 0.48,
      scaleY: 0.48
    }
  };
}, {});

export default {
  objectToLoad,
  loadedObjectSettings: {
    title: `Цвета календарной сетки`,
    colorPickers: [
      {
        label: `Будни`,
        pathNumber: 0
      },
      {
        label: `Выходные`,
        pathNumber: 1
      },
      {
        label: `Фон месяца`,
        pathNumber: 2
      },
      {
        label: `Название месяца`,
        pathNumber: 3
      }
    ]
  }
};
