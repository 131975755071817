function drawGuideLines(canvas, coords, offset = 0) {
  const ctx = canvas.contextTop;
  ctx.strokeStyle = `rgb(190,190,190)`;
  ctx.beginPath();
  _drawLines(ctx, coords, offset);
  ctx.stroke();
}

function _drawLines(ctx, [top, left, width, height], edgeOffset) {
  const fabric = window && window.fabric;
  fabric.util.drawDashedLine(
    ctx,
    left + parseInt(edgeOffset, 10),
    top + parseInt(edgeOffset, 10),
    left + parseInt(edgeOffset, 10),
    top + height - parseInt(edgeOffset, 10),
    [5, 5]
  );
  fabric.util.drawDashedLine(
    ctx,
    left + parseInt(edgeOffset, 10),
    top + height - parseInt(edgeOffset, 10),
    left + width - parseInt(edgeOffset, 10),
    top + height - parseInt(edgeOffset, 10),
    [5, 5]
  );
  fabric.util.drawDashedLine(
    ctx,
    left + width - parseInt(edgeOffset, 10),
    top + height - parseInt(edgeOffset, 10),
    left + width - parseInt(edgeOffset, 10),
    top + parseInt(edgeOffset, 10),
    [5, 5]
  );
  fabric.util.drawDashedLine(
    ctx,
    left + width - parseInt(edgeOffset, 10),
    top + parseInt(edgeOffset, 10),
    left + parseInt(edgeOffset, 10),
    top + parseInt(edgeOffset, 10),
    [5, 5]
  );
}

export default drawGuideLines;
